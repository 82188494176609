export const AppRoutes = {
    Dashboard: {
        Index: '/aplikacije',
        Subsystem: '/aplikacije/:id',
        Profile: '/aplikacije/moj-profil',
        Login: '/login',
        Logout: '/logout',
        EObrasci: '/aplikacije/eObrasci',
        LogoutRedirect: '/logout-redirect'
    }, 
    Landing: {
        Index: '',
        HowToApply: '/kako-se-prijaviti',
        LoginRedirect: '/login-redirect'
    },
    EBusiness: {
        ApplyRoles: '/apply-roles',
    },
    NotFound: "/404"
}

export const generateRouteWithParams = (appRoute: string, params: any): string => {
    for (let [key, value] of Object.entries(params)) {
        //@ts-ignore
        appRoute = appRoute.replace(`:${key}`, value);
    }
    return appRoute;
}