import React from "react";
import './eObrasci.styles.scss';
import { StoreStateInterface } from "../../../../common";
import { connect } from "react-redux";
import { withKeycloak } from '@react-keycloak/web';
import { RouteComponentProps } from "react-router-dom";
import { withRouter } from 'react-router';
import { KeycloakInstance } from "keycloak-js";

type PathParamsType = {
}

type PropsType = RouteComponentProps<PathParamsType> & {
    keycloakInitialized: boolean;
    keycloak: KeycloakInstance;
}

interface State {
}

class EObrasciComponent extends React.Component<PropsType, State> {
    render() {
        return (
            <article className="eObrasci">
                <div className="title title--green applications__in-develop">
                    <div>eObrasci su trenutno u izradi</div>
                </div>
            </article>
        );
    }
}

const mapStateToProps = (state: StoreStateInterface) => {
    return {
    }
}

const actions = {
};

const redux = withRouter(connect(
    mapStateToProps,
    actions
)(EObrasciComponent));

export default withKeycloak(redux)