import { Dispatch } from "redux";
import { AuthConstant } from "./auth.constant";
import { BlockUIConstant } from "../base";
import { SubjectService } from "../../api";
import { UserInterface } from "../../interfaces";
import keycloak from "../../../keycloak";
import queryString from "query-string";

export function actionLogout() {
    return (dispatch: Dispatch) => {
        dispatch({ type: AuthConstant.LOGOUT });
    };
}

export function actionRevoke(user: UserInterface) {
    return (dispatch: Dispatch) => {
        dispatch({ type: BlockUIConstant.START });
        user.privola = false;
        SubjectService.patchSubjectByOIB(user.oib!, user).subscribe((response: any) => {
            keycloak.logout();
            dispatch({ type: AuthConstant.LOGOUT });
            dispatch({ type: BlockUIConstant.END });
        }, (error: any) => {
            dispatch({ type: BlockUIConstant.END });
        })
    };
}

export function actionGetSubject(user: UserInterface) {
    return (dispatch: Dispatch, getState: any) => {
        setTimeout(() => {
            const { auth } = getState();
            const result = queryString.parse(window.location.search);
            const { ForLegalIzvor_reg = undefined, ForLegalIps = undefined, ToLegalIzvor_reg = undefined, ToLegalIps = undefined } = result;
            
            let identifier = '';
            if (ForLegalIps !== undefined && ForLegalIps !== '') {
                SubjectService.geteAuthorizationSubject(ForLegalIps as string, ForLegalIzvor_reg as string, ToLegalIps as string, ToLegalIzvor_reg as string, undefined).subscribe((response: any) => {
                    dispatch({ type: AuthConstant.GET_SUBJECT, payload: response })
                }, (error: any) => {
                    console.error(error);
                })
            }
            else {
                identifier = encodeURIComponent(auth?.user.oib);
                SubjectService.getSubjectByOIB(identifier, undefined).subscribe((response: any) => {
                    dispatch({ type: AuthConstant.GET_SUBJECT, payload: response })
                }, (error: any) => {
                    console.error(error);
                })
            }
                


        }, 100);
    };
}

export function actionEnablePopUp() {
    return (dispatch: Dispatch) => {
        dispatch({ type: AuthConstant.ENABLE_POPUP });
    };
}

export function actionChangeOib(oib: string) {
    return (dispatch: Dispatch) => {
        dispatch({ type: AuthConstant.CHANGE_OIB, payload: oib });
    };
}

export function actionUpdateToken(accessToken: string, refreshToken: string) {
    return (dispatch: Dispatch, getState: any) => {
        dispatch({ type: AuthConstant.REFRESH_TOKEN, payload: {accessToken, refreshToken} });
    };
}


