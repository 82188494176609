import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { KeycloakError } from "keycloak-js";
import React from "react";
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter as Router } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { AppRoutes, AuthConstant, BlockUIConstant, ErrorConstant, NavigationService, SubjectService, UserInterface, persistor, setLang, store } from "../../../common";
import keycloak from '../../../keycloak';
import { MainNavigationRouting } from "./main.routing";

type Props = {};

interface State {
    fontSize: string;
    oib?: any;
    showOdjava: boolean;
    refreshLoop: boolean;
}

export class MainNavigationRoutingComponent extends React.Component<Props, State> {
    private interval: any;
    private headerInterval: any;

    constructor(props: Props) {
        super(props);

        this.state = {
            fontSize: "16px",
            showOdjava: false,
            refreshLoop: false
        }
    }

    getStyle(el: any, styleProp: any) {
        var value, defaultView = (el.ownerDocument || document).defaultView;
        // W3C standard way:
        if (defaultView && defaultView.getComputedStyle) {
            // sanitize property name to css notation
            // (hypen separated words eg. font-Size)
            styleProp = styleProp.replace(/([A-Z])/g, "-$1").toLowerCase();
            return defaultView.getComputedStyle(el, null).getPropertyValue(styleProp);
        } else if (el.currentStyle) { // IE
            // sanitize property name to camelCase
            /* eslint-disable-next-line*/
            styleProp = styleProp.replace(/\-(\w)/g, function (str: any, letter: any) {
                return letter.toUpperCase();
            });
            value = el.currentStyle[styleProp];
            // convert other units to pixels on IE
            /* eslint-disable-next-line*/
            if (/\d+(em|pt|%|ex)?$/i.test(value)) {
                return (function (value) {
                    var oldLeft = el.style.left, oldRsLeft = el.runtimeStyle.left;
                    el.runtimeStyle.left = el.currentStyle.left;
                    el.style.left = value || 0;
                    value = el.style.pixelLeft + "px";
                    el.style.left = oldLeft;
                    el.runtimeStyle.left = oldRsLeft;
                    return value;
                })(value);
            }
            return value;
        }
    }

    componentDidUpdate() {
        let size = parseFloat(this.state.fontSize.replace("px", ''));
        document.getElementsByTagName('body')[0].style.fontSize = (size / 16 * 100).toString() + "%";
    }

    private getCookie(cname: string): string {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    refreshLang() {
        this.setState({ refreshLoop: !this.state.refreshLoop })
    }

    componentDidMount() {
        document.addEventListener("refreshLang", this.refreshLang.bind(this))

        this.interval = setInterval(() => {
            //@ts-ignore
            if (this.getCookie("cookieImpaired") === "da" && !document.body.classList.contains("dark")) {
                //@ts-ignore
                document.body.classList.add("dark");
                //@ts-ignore
            } else if (this.getCookie("cookieImpaired") !== "da" && document.body.classList.contains("dark")) {
                //@ts-ignore
                document.body.classList.remove("dark");
            }
        }
            , 1000)

        this.headerInterval = setInterval(() => {
            if (window.location.href.includes("/aplikacije") && !this.state.showOdjava) {
                this.setState({ showOdjava: true })
            } else if (!window.location.href.includes("/aplikacije") && this.state.showOdjava) {
                this.setState({ showOdjava: false })
            }
        }
            , 1000)
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        clearInterval(this.headerInterval);
        document.removeEventListener("refreshLang", this.refreshLang.bind(this))
    }

    render() {
        const theme = createMuiTheme({
            palette: {
                primary: {
                    main: "#41927F"
                }
            },
            zIndex: {
                appBar: 100000
            },
        });

        setInterval(() => {
            let filterHolder = document.getElementById("filter_holder");
            if (filterHolder !== null)
                if (this.getStyle(filterHolder, "font-size") !== this.state.fontSize)
                    this.setState({ fontSize: this.getStyle(filterHolder, "font-size") })
        }, 1000);
        return (

            <ReactKeycloakProvider authClient={keycloak} initOptions={{
                onLoad: 'check-sso',
                silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html'
            }}
                onEvent={(
                    eventType: any,
                    error?: KeycloakError
                ) => {
                    if (!store.getState().auth.isAuthentificated && !!keycloak && !!keycloak.tokenParsed && (keycloak!.tokenParsed! as any).OznakaDrzaveEid !== "HR" && eventType === "onAuthSuccess") {
                        setLang("en")
                    }
                    if (!store.getState().auth.isAuthentificated && !!keycloak && !!keycloak.tokenParsed && eventType === "onAuthSuccess") {
                        localStorage.setItem("entitySearch", "1")
                        localStorage.removeItem('ENTITY_DATA');
                    }
                    if (window.location.href.includes(AppRoutes.Dashboard.LogoutRedirect)) return;
                    if (keycloak.authenticated && !store.getState().auth.isAuthentificated && eventType === "onAuthSuccess") {
                        let data: any = {
                            token: keycloak.token,
                            refresh_token: keycloak.refreshToken
                        }
                        //@ts-ignore
                        let tokenParsed = keycloak!.tokenParsed! as any;
                        let oib = tokenParsed.oib;
                        if (oib === undefined) {
                            oib = encodeURIComponent(tokenParsed.preferred_username);

                        }
                        let username = tokenParsed.preferred_username;
                        SubjectService.getSubjectByOIB(oib, data.token).subscribe((user: UserInterface) => {
                            if (user === null) return;
                            user.username = username;
                            data.user = user;
                            data.MessageId = tokenParsed.MessageId;
                            data.NavToken = tokenParsed.NavToken;
                            store.dispatch({ type: AuthConstant.LOGIN, payload: data });
                            store.dispatch({ type: BlockUIConstant.END });
                        }, (error: any) => {
                            if (localStorage.getItem("registerStarted") === null && error.message.toString() === "404") {
                                localStorage.setItem("registerStarted", "1");
                                SubjectService.createSubjectByOIB(oib, keycloak.token).subscribe((user: any) => {
                                    setTimeout(() => {
                                        let tokenParsed = keycloak!.tokenParsed! as any;
                                        SubjectService.getSubjectByOIB(oib, keycloak.token!).subscribe((user: UserInterface) => {
                                            let data: any = {
                                                token: keycloak.token,
                                                refresh_token: keycloak.refreshToken
                                            }
                                            data.user = user;
                                            data.user.username = tokenParsed.preferred_username;
                                            data.MessageId = tokenParsed.MessageId;
                                            data.NavToken = tokenParsed.NavToken;
                                            store.dispatch({ type: AuthConstant.LOGIN, payload: data });
                                            store.dispatch({ type: BlockUIConstant.END });
                                        });
                                    }, 1000);
                                }, (error: any) => {
                                    store.dispatch({
                                        type: ErrorConstant.ERROR, payload:
                                        {
                                            message: "Greška kod kreiranja korisnika",
                                            action: () => { }
                                        }
                                    }
                                    )
                                    keycloak.logout();
                                    store.dispatch({ type: BlockUIConstant.END });
                                })
                            }
                        }
                        )
                    } else {
                        store.dispatch({ type: BlockUIConstant.END });
                    }
                }}>
                <ReduxProvider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        <div className={this.state.showOdjava ? "egradani_traka-holder" : "egradani_traka-holder landing-traka"}></div>
                        <ThemeProvider theme={theme}>
                            <Routes />
                        </ThemeProvider>
                    </PersistGate>
                </ReduxProvider>
            </ReactKeycloakProvider>

        );
    }
}


class Routes extends React.Component<{}> {
    render() {
        return (
            <>
                <Router ref={(navigatorRef: any) =>
                    NavigationService.setTopLevelNavigator(navigatorRef)
                }>
                    {NavigationService.generateRoutes(MainNavigationRouting())}
                </Router>
            </>
        );
    }
}





