import { Dispatch } from "redux";
import { AuthorizationService } from "../../api";
import { EBusinessConstant } from "./e-business.constant";
import { AuthFormData } from "./e-business.reducer";
import { store } from "../store";
import { ErrorConstant } from "../base";
import { i18n } from "../../services";

export function actionGetAuthFormData(requestId: string) {
    return (dispatch: Dispatch) => {
        AuthorizationService.getAuthorizationFormData(requestId).subscribe(async (response: AuthFormData) => {
            dispatch({ type: EBusinessConstant.AUTH_FORM, payload: response });
        }, (error: any) => {
            console.error(error);
        });
    };
}

export function actionGetPermissions(oib: string) {
    return (dispatch: Dispatch) => {
        AuthorizationService.getPermissions(oib).subscribe((response: AuthFormData) => {
            dispatch({ type: EBusinessConstant.PERMISSIONS, payload: response })
        }, (error: any) => {
            console.error(error);
        });
    };
}

export function actionGrantAccess(data: AuthFormData, roles: any) {
    return (dispatch: Dispatch) => {
        AuthorizationService.grantAccess(data, roles).subscribe((response: any) => {
            dispatch({ type: EBusinessConstant.GRANT_ACCESS, payload: { serviceResponse: response?.serviceResponse, submitUrl: response?.submitUrl } });
        }, (error: any) => {
            store.dispatch({
                type: ErrorConstant.ERROR, payload:
                {
                    message: i18n.t("error.eovlastenja"),
                    action: () => { }
                }
            }
            )
        })
    };
}