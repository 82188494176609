import { KeyCloakRoute } from "../../keycloak";

export const ApiRoutes = {
    GetApplicationsByCategoryId: (categoryId: number) => `/api/Home/category/${categoryId}`,
    GetApplications: `/api/Home/category`,
    GetUserByOIB: (oib: string) => `/api/Home/subject/${oib}`,
    GeteAuthorizationUserByOIB: (ForLegalIps: string, ForLegalIzvor_reg: string, ToLegalIps: string, ToLegalIzvor_reg: string) => `/api/Home/subject/${ForLegalIps}/${ToLegalIps}/${ForLegalIzvor_reg}/${ToLegalIzvor_reg}`,
    PatchUserByOIB: (oib: string) => `/api/Home/subject/${oib}`,
    CreateUserByOIB: (oib: string) => `/api/Home/subject/${oib}`,
    SessionInspector: `${KeyCloakRoute}/realms/RPS/session-inspector`,
    Document: (subsystemId: number) => `/api/home/registrationDoc/${subsystemId}`,
    SendEmailVerification: `/api/home/sendemailverification`,
    VerifyEmail: `/api/home/verifyemail`,
    DeleteEmail: (oib: string, ForLegalIzvor_reg?: string) => ForLegalIzvor_reg ? `/api/home/deleteemail/${oib}/${ForLegalIzvor_reg}` : `/api/home/deleteemail/${oib}`,
    DisableEmailPopUp: (oib: string, ForLegalIzvor_reg?: string) => ForLegalIzvor_reg ? `/api/home/disableemailpopup/${oib}/${ForLegalIzvor_reg}` : `/api/home/disableemailpopup/${oib}`,
    GetAuthorizationFormData: (requestId: string) => `/eauthorization/api/v1/form-data?requestId=${requestId}`,
    GetPermissions: (oib: string) => `/api/home/permissions/${oib}`,
    GrantAccess: `eauthorization/api/v1/grant`
}