import React from "react";
import { connect } from "react-redux";
import './howToApply.styles.scss';
import { StoreStateInterface, NavigationService, AppRoutes } from "../../../../common";
import { AiOutlineClose } from 'react-icons/ai';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Image1 from '../../../../assets/images/image1.png';
import Image2 from '../../../../assets/images/image2.png';
import Image3 from '../../../../assets/images/image3.png';
import { withKeycloak } from '@react-keycloak/web'
import { KeycloakInstance } from "keycloak-js";
import i18n from "../../../../common/services/translate.service";

type PropsType = {
    keycloakInitialized: boolean;
    keycloak: KeycloakInstance;
};

interface State {
    step: number;
}

class HowToApplyComponent extends React.Component<PropsType, State> {
    constructor(props: PropsType) {
        super(props);

        this.state = {
            step: 0
        }

        this.createRightSide = this.createRightSide.bind(this);
    }

    private createRightSide(): React.ReactNode {
        var rows = [];
        for (var i = 0; i < 2; i++) {
            rows.push(
                <React.Fragment key={i}>
                    {i === this.state.step &&
                        <div>
                            <div className="title title--green">
                                {i === 0 && i18n.t('howToLoginPage.eCitizenDigitalPortal.title')}
                                {i === 1 && i18n.t('howToLoginPage.loginToNIAS.title')}
                            </div>
                            <div className="howToApply__text">
                                <div style={i !== 0 ? { display: "none" } : {}}>
                                    <p>{i18n.t('howToLoginPage.eCitizenDigitalPortal.paragraph1')}</p>
                                    <p>{i18n.t('howToLoginPage.eCitizenDigitalPortal.paragraph2')}</p>
                                    <p>{i18n.t('howToLoginPage.eCitizenDigitalPortal.paragraph3')}</p>
                                    <img src={Image1} alt="image1" />
                                </div>
                                <div style={i !== 1 ? { display: "none" } : {}}>
                                    <p>{i18n.t('howToLoginPage.loginToNIAS.paragraph1')}</p>
                                    <img src={Image2} alt="image2" />
                                    <p>{i18n.t('howToLoginPage.loginToNIAS.paragraph2')}</p>
                                    <img src={Image3} alt="image3" />
                                </div>
                            </div>
                        </div>
                    }
                </React.Fragment >);
        }
        return <div className="howToApply__content-right">{rows}</div>;
    }

    render() {
        return (
            <section>
                <div className="howToApply__close" onClick={() => NavigationService.navigate(AppRoutes.Landing.Index)}>
                    <AiOutlineClose size={18}></AiOutlineClose>
                </div>
                <div className="howToApply">
                    <div className="howToApply__title">
                        <div className="title title--green title--bold">{i18n.t("howToLoginPage.title")}</div>
                    </div>
                    <div className="howToApply__content">
                        <div className="howToApply__content-left">
                            <Stepper className="custom-stepper" orientation="vertical" nonLinear activeStep={this.state.step}>
                                <Step key={1}>
                                    <StepButton onClick={() => this.setState({ step: 0 })}>
                                        {i18n.t('howToLoginPage.eCitizenDigitalPortal.title')}
                                    </StepButton>
                                </Step>
                                <Step key={2}>
                                    <StepButton onClick={() => this.setState({ step: 1 })}>
                                        {i18n.t("howToLoginPage.loginToNIAS.title")}
                                    </StepButton>
                                </Step>
                            </Stepper>
                        </div>
                        {this.createRightSide()}
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = (state: StoreStateInterface) => {
    return {
    }
}

const actions = {
};

const redux = connect(
    mapStateToProps,
    actions
)(HowToApplyComponent);

export default withKeycloak(redux)