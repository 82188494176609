import React, { Component } from "react";
import './button.styles.scss';


interface Props {
    type: "contained" | "outlined" | "plain" | "link" | "link2" | "link3";
    onClick: any;
    icon?: React.ReactNode;
    style?: any;
    ariaLabel?: any;
}

interface State {
}

export class Button extends Component<Props, State> {
    private class: string = "button";

    render() {
        this.class = "button";
        switch (this.props.type) {
            case "contained": this.class += " button--contained";  break;
            case "outlined": this.class += " button--outlined";  break;
            case "plain": this.class += " button--plain"; break;
            case "link": this.class += " button--link"; break;
            case "link2": this.class += " button--link2"; break;
            case "link3": this.class += " button--link3"; break;
        }
        return (
            <button className={this.class} onClick={this.props.onClick} style={this.props.style} aria-label={this.props.ariaLabel} >
                <span className="button__text">
                    {this.props.children}
                </span>
                { this.props.icon && 
                    this.props.icon
                }
            </button>
        )
    }
}