import { AnyAction } from "redux";
import { ErrorConstant } from "./error.constant";
import { ErrorPayloadInterface } from "../../../interfaces";

export interface ErrorStateInterface {
    error: boolean;
    message?: string;
    action?: () => void;
}

const initialState: ErrorStateInterface = {
    error: false
}

export const error = (payload: ErrorPayloadInterface): ErrorStateInterface => {
    return {
        error: true,
        message: payload.message,
        action: payload.action
    }
}

export const refresh = () => {
    return {
        error: false
    }
}



export function errorReducer(state: ErrorStateInterface = initialState, action: AnyAction): ErrorStateInterface {
    switch(action.type) {
        case ErrorConstant.ERROR: return error(action.payload);
        case ErrorConstant.REFRESH: return refresh();
    }
    return state;
}
