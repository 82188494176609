import React from "react";
import { LandingNavigationRouting } from "./landing.routing";
import { NavigationService, StoreStateInterface, ErrorStateInterface, Alert, actionRefreshError, actionLogout, AuthStateInterface, AppRoutes } from "../../../common";
import { connect } from "react-redux";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';

type Props = {
    error: ErrorStateInterface;
    auth: AuthStateInterface;
    actionRefreshError: () => void;
    blockUI: boolean;
    actionLogout: () => void;
};

export function appendLandingEcitizensHeader(lang: string, removeCookies?: boolean) {
    let trakica = document.getElementById("trakicaScript");
    let trakicaSrc = `${process.env.REACT_APP_NIAS_HEADER_URL}?login_url=${AppRoutes.Landing.LoginRedirect}&language=${lang}`
    let egradjani = document.getElementById("egradjani");
    let scriptElement = document.querySelector('script[src="https://eusluge-nav-test.gov.hr/js/zaglavlje.js"]');

    if (trakica !== null) {
        trakica.remove();

    }
    if (scriptElement !== null) {
        scriptElement.remove();
    }
    if (egradjani !== null) {
        egradjani.remove();
    }

    let script = document.createElement("script");

    script.src = trakicaSrc;
    script.async = true;
    script.id = "trakicaScript"
    document.body.appendChild(script);
}

class LandingNavigationRoutingComponent extends React.Component<Props> {
    componentDidMount() {
        if (!this.props.auth.isAuthentificated && !window.location.pathname.includes(AppRoutes.Landing.LoginRedirect)) {
            appendLandingEcitizensHeader(window.localStorage.getItem("lang") !== null ? window.localStorage.getItem("lang")! : "hr", !this.props.auth.isAuthentificated);
        }
    }


    componentWillUnmount() {
    }

    render() {
        if (localStorage.getItem("logout") === "1") {
            setTimeout(() => { localStorage.removeItem("logout") }, 10000)
            return (
                <div>Preusmjeravanje...</div>
            )
        }
        return (
            <div>
                {NavigationService.generateRoutes(LandingNavigationRouting())}
                <Snackbar open={this.props.error.error && this.props.error.message !== undefined && this.props.error.message.length > 0} autoHideDuration={6000} onClose={() => {
                    this.props.actionRefreshError()
                }}>
                    <Alert onClose={() => { if (this.props.error.action !== undefined) this.props.error.action(); else this.props.actionRefreshError() }} severity="error">
                        {this.props.error.message}
                    </Alert>
                </Snackbar>
                <Backdrop open={this.props.blockUI} style={{ zIndex: 500 }}>
                    <CircularProgress color="primary" />
                </Backdrop>
            </div>
        )
    }
}



const mapStateToProps = (state: StoreStateInterface) => {
    return {
        error: state.base.error,
        blockUI: state.base.blockUI,
        auth: state.auth
    }
}

const actions = {
    actionRefreshError,
    actionLogout
};

export default connect(
    mapStateToProps,
    actions
)(LandingNavigationRoutingComponent);


