import { RouteInterface } from "../../../common/interfaces/framework/routing.interface";
import { AppRoutes } from "../../../common";
import LandingComponent from "../pages/landing/landing.component";
import HowToApplyComponent from "../pages/howToApply/howToApply.component";
import LoginRedirectComponent from "../pages/loginRedirect/loginRedirect.component";

export const LandingNavigationRouting = (): Array<RouteInterface> => {
    return [
        { path: AppRoutes.Landing.LoginRedirect, component: LoginRedirectComponent, exact: true },
        {path: AppRoutes.Landing.HowToApply, component: HowToApplyComponent, exact: true},
        { path: AppRoutes.Landing.Index, component: LandingComponent, exact: true }
    ]
}