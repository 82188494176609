import React from "react";
import { NavigationService } from "../../../common";
import { EBusinessNavigationRouting } from "./e-business.routing";

const EBusinessNavigationRoutingComponent = () => {
    return (
        <div>
            {NavigationService.generateRoutes(EBusinessNavigationRouting())}
        </div>
    );
}

export default EBusinessNavigationRoutingComponent;


