import React, { Component } from "react";
import './appHeader.styles.scss';
import { RouteComponentProps } from "react-router-dom";
import { withRouter } from 'react-router';
import qs from 'qs';
import { AppRoutes, LinksConstant, QueryParamsConstant } from "../../constants";
import { NavigationService } from "../../index";
import { SystemsStateInterface } from "../../redux/systems";
import Logo from '../../../assets/images/logo.svg';
import LogoEn from '../../../assets/images/logo-en.svg';
import LogoDark from '../../../assets/images/logo-hr-dark.svg';
import LogoEnDark from '../../../assets/images/logo-en-dark.svg';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { actionGetSubsystems, AuthStateInterface, actionChangeOib } from "../../redux";
import keycloak from "../../../keycloak";
import { List, ListItem, ListItemText } from '@material-ui/core';
import MpsImage from '../../../assets/images/mp-logo.svg';
import MpsEnImage from '../../../assets/images/mp-logo-en.svg';
import MpsImageDark from '../../../assets/images/mp-logo-dark.svg';
import MpsEnImageDark from '../../../assets/images/mp-logo-en-dark.svg';
import EUImage from '../../../assets/images/european-union-logo.svg';
import EUEnImage from '../../../assets/images/european-union-logo-en.svg';
import EUImageDark from '../../../assets/images/european-union-logo-dark.png';
import EUEnImageDark from '../../../assets/images/european-union-logo-en-dark.png';
import { connect } from "react-redux";
import { appendDashboardEcitizensHeader } from "../../../features/dashboard/navigation/dashboard.component";
import i18n, { setLang } from "../../services/translate.service";

interface Props {
    systems: SystemsStateInterface;
    auth: AuthStateInterface;
    actionLogout: () => void;
    actionGetSubsystems: (lang: string) => void;
    actionChangeOib: any;
}

interface State {
    currentCategoryId: number;
    hamburger: boolean;
    lang: string;
    isDarkMode: boolean;
}

type PathParamsType = {
}

const lngs = {
    'en': { nativeName: 'EN' },
    'hr': { nativeName: 'HR' }
} as LngsType;

type LngsType = {
    [key: string]: { [key: string]: string }
}

type PropsType = RouteComponentProps<PathParamsType> & Props;

const getQueryData = () => {
    const entityDataJSON = localStorage.getItem('ENTITY_DATA');

    let entityData;
    if (entityDataJSON) {
        entityData = JSON.parse(entityDataJSON);
        const { ForLegalIps = undefined, ForLegalIzvor_reg = undefined, ToLegalIps = undefined, ToLegalIzvor_reg = undefined, ForPersonOib = undefined } = entityData;
        if (ForLegalIps || ForLegalIzvor_reg || ToLegalIps || ToLegalIzvor_reg || ForPersonOib) {
            return `?ForLegalIps=${ForLegalIps}&ForPersonOib=${ForPersonOib}&ForLegalIzvor_reg=${ForLegalIzvor_reg}&ToLegalIps=${ToLegalIps}&ToLegalIzvor_reg=${ToLegalIzvor_reg}`;
        } else {
            return '';
        }
    }
    return '';
}

class AppHeader extends Component<PropsType, State> {
    unlisten: any;
    private ref: React.RefObject<any>;
    private interval: any;

    constructor(props: PropsType) {
        super(props);
        let categoryId: number = parseInt(this.getCategory(this.props.location));
        if (Number.isNaN(categoryId) || categoryId > 4) {
            categoryId = 0
        }
        this.ref = React.createRef();

        this.state = {
            currentCategoryId: categoryId,
            hamburger: false,
            lang: window.localStorage.getItem("lang") ? window.localStorage.getItem("lang")! : "hr",
            isDarkMode: false
        }

        this.isActive = this.isActive.bind(this);
    }

    getCategory(location: any): string {
        let qp = qs.parse(location.search.replace('?', ''));
        let categoryId: string = "1";
        if (qp.categoryId) {
            categoryId = qp.categoryId.toString();
        }
        return decodeURI(categoryId);
    }

    refreshLang() {
        this.setState({ lang: window.localStorage.getItem("lang") ? window.localStorage.getItem("lang")! : "hr"})
    }


    componentDidMount() {
        this.interval = setInterval(() => {
            //@ts-ignore
            if (this.getCookie("cookieImpaired") === "da") {
                this.setState({ isDarkMode: true });
            }
            else {
                this.setState({ isDarkMode: false });
            }
        }
            , 1000)
        document.addEventListener("refreshLang", this.refreshLang.bind(this))
        this.unlisten = this.props.history.listen((location, action) => {
            let categoryId: number = parseInt(this.getCategory(location));
            if (Number.isNaN(categoryId) || categoryId > 4) {
                categoryId = 0
            }
            if (location.pathname === AppRoutes.Dashboard.Profile) categoryId = 0;
            this.setState({ currentCategoryId: categoryId })
        });
    }

    componentWillUnmount() {
        this.unlisten();
        document.removeEventListener("refreshLang", this.refreshLang.bind(this))
    }


    isActive(data: any): boolean {
        let currentId = this.state.currentCategoryId;
        if (!!this.props.location.state) return false;
        if (this.props.location.pathname === AppRoutes.Dashboard.Profile || this.props.location.pathname === AppRoutes.Dashboard.EObrasci) currentId = 0;
        if (data.categoryId !== undefined) {
            return (currentId === 0 && data.categoryId === 1 && this.props.location.pathname !== AppRoutes.Dashboard.Profile && this.props.location.pathname !== AppRoutes.Dashboard.EObrasci) || currentId === data.categoryId;
        } else if (data.url !== undefined && data.url === this.props.location.pathname) {
            return true;
        }
        return false;
    }

    private openHamburger(): void {
        this.setState({ hamburger: true });
        document.body.style.overflow = "hidden";
    }

    private closeHamburger(): void {
        this.setState({ hamburger: false });
        document.body.style.overflow = "auto";
    }

    private routeToProfilePage(): void {
        NavigationService.navigate(`${AppRoutes.Dashboard.Profile}${getQueryData()}`);        
    }

    private onKeyDownHandler(event: React.KeyboardEvent<HTMLInputElement>): void {
        if (event.key === 'Enter') {
            NavigationService.navigate(`${AppRoutes.Dashboard.Profile}${getQueryData()}`);
        }
    }

    routeToDashboard(): void {
        NavigationService.navigate(`${AppRoutes.Dashboard.Index}${getQueryData()}`);
    }

    private renderMobileMenu(): React.ReactNode {
        return (
            <List>
                <ListItem button
                    onClick={
                        () => {
                            this.setState({ hamburger: false });
                            document.body.style.overflow = "auto";
                            NavigationService.navigate(AppRoutes.Dashboard.Index)
                        }
                    }
                >
                    <ListItemText primary={i18n.t('mobileNav.applications')} />
                </ListItem>
                <ListItem button 
                    onClick={
                        () => {
                            this.setState({ hamburger: false });
                            document.body.style.overflow = "auto";
                            NavigationService.navigate(AppRoutes.Dashboard.Profile)
                        }
                    }
                >
                    <ListItemText primary={i18n.t('mobileNav.myData')} />
                </ListItem>
                <ListItem button 
                    onClick={
                        () => {
                            if (localStorage.getItem("agronetLogin") === "1") {
                                localStorage.removeItem("agronetLogin");
                                localStorage.setItem("logout", "1");
                                this.setState({ hamburger: false });
                                let item: string = localStorage.getItem("agronetUrl") !== null ? localStorage.getItem("agronetUrl")! : process.env.REACT_APP_AGRONET!;
                                setTimeout(() => {
                                    window.location.href = `${item}Logout2.aspx?${QueryParamsConstant.SsoLogoutUrl}=${process.env.REACT_APP_ROOT!}`;
                                }, 1000)
                            } else {
                                this.setState({ hamburger: false });
                                document.body.style.overflow = "auto";
                                keycloak.logout();
                                this.props.actionLogout();
                            }
                        }
                    }
                >
                    <ListItemText primary={i18n.t('mobileNav.logout')} />
                </ListItem>
            </List>
        )
    }

    private getCookie(cname: string): string {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    getLogoSrc = () => {
        const lang = window.localStorage.getItem("lang") !== null ? window.localStorage.getItem("lang")! : "hr";
        const isDarkMode = this.state.isDarkMode;

        if (lang === "hr" && isDarkMode) {
            return LogoDark;
        } else if (lang === "hr" && !isDarkMode) {
            return Logo;
        } else if (lang !== "hr" && isDarkMode) {
            return LogoEnDark;
        } else {
            return LogoEn;
        }
    };

    GetMpsLogoSrc = () => {
        const lang = window.localStorage.getItem("lang") !== null ? window.localStorage.getItem("lang")! : "hr";
        const isDarkMode = this.state.isDarkMode;

        if (lang === "hr" && isDarkMode) {
            return MpsImageDark;
        } else if (lang === "hr" && !isDarkMode) {
            return MpsImage;
        } else if (lang !== "hr" && isDarkMode) {
            return MpsEnImageDark;
        } else {
            return MpsEnImage;
        }
    };

    GetEULogoSrc = () => {
        const lang = window.localStorage.getItem("lang") !== null ? window.localStorage.getItem("lang")! : "hr";
        const isDarkMode = this.state.isDarkMode;

        if (lang === "hr" && isDarkMode) {
            return EUImageDark;
        } else if (lang === "hr" && !isDarkMode) {
            return EUImage;
        } else if (lang !== "hr" && isDarkMode) {
            return EUEnImageDark;
        } else {
            return EUEnImage;
        }
    };

    render() {
        return (
            <nav className="appHeader">
                <div className="appHeader__container">
                    <div className="appHeader__title-container">
                        <div className="appHeader__title" onClick={this.routeToDashboard}>
                            <img tabIndex={0} src={this.getLogoSrc()} alt={this.state.lang === 'hr' ? "ePoljoprivreda po�etna stranica" : "eAgriculture home page"} />
                        </div>
                        <div className="appHeader__title-mobile">
                            <div className="appHeader__translation">
                                {Object.keys(lngs).map((lng, index) => {
                                    const isActive = (window.localStorage.getItem("lang") !== null ? window.localStorage.getItem("lang") : "hr") === lng;
                                    return (
                                        <button
                                            key={lng}
                                            onClick={() => {
                                                setLang(lng)
                                                //appendDashboardEcitizensHeader(lng, this.props.auth, this.props.actionChangeOib);
                                                //this.props.actionGetSubsystems(lng);
                                                window.location.reload();
                                            }}
                                            className={`appHeader__translation-button ${isActive ? 'active' : ''}`}
                                            aria-label={lng === 'hr' ? "Promjeni jezik na hrvatski" : "Switch language to english"}
                                        >
                                            {lngs[lng].nativeName}
                                        </button>
                                    );
                                })}
                            </div>
                            <MenuIcon onClick={this.openHamburger.bind(this)} color="primary" style={{ cursor: 'pointer' }} />
                            <div className={this.state.hamburger ? "appHeader__right active" : "appHeader__right"}>
                                <div className="appHeader__right-header" style={{ cursor: 'pointer' }}>
                                    <img src={Logo} alt="logo"/>
                                    <CloseIcon onClick={this.closeHamburger.bind(this)} />
                                </div>
                                <div>
                                    {this.renderMobileMenu()}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className="appHeader__tabs">
                            <a href={LinksConstant.ministarstvo} target="_blank" rel="noopener noreferrer" style={{ marginRight: 24 }}>
                                <img src={this.GetMpsLogoSrc()} alt={this.state.lang === 'hr' ? "Ministarstvo poljoprivrede, �umarstva i ribarstva" : "Ministry of Agriculture, Forestry and Fisheries"} style={{ width: 200 }}></img>
                            </a>    
                            <a href={LinksConstant.eu} target="_blank" rel="noopener noreferrer" style={{ marginRight: 48 }}>
                                <img src={this.GetEULogoSrc()} alt={this.state.lang === 'hr' ? "Financira Europska unija" : "Funded by EU"} style={{ width: 200 }}></img>
                            </a>
                        </div>
                        <div className="appHeader__tabs">
                            <div className="appHeader__translation">
                            {Object.keys(lngs).map((lng, index) => {
                                const borderRight = index === 0 ? '2px solid #839994' : 'none';
                                const isActive = (window.localStorage.getItem("lang") !== null ? window.localStorage.getItem("lang") : "hr") === lng;
                                return (

                                    <button
                                        key={lng}
                                        onClick={() => {
                                            setLang(lng)
                                            //appendDashboardEcitizensHeader(lng, this.props.auth, this.props.actionChangeOib);
                                            //this.props.actionGetSubsystems(lng);
                                            window.location.reload();
                                        }}
                                        className={`appHeader__translation-button ${isActive ? 'active' : ''}`}
                                        aria-label={lng === 'hr' ? "Promjeni jezik na hrvatski" : "Switch language to english"}
                                    >
                                    {lngs[lng].nativeName}
                                    </button>
                                );
                            })}
                            </div>
                        </div>
                        <div className="appHeader__tabs">
                            <div
                                tabIndex={0}
                                style={{ width: 100 }}
                                ref={this.ref}
                                aria-controls={undefined}
                                aria-label={this.state.lang === 'hr' ? "Moji podaci" : "My data"}
                                aria-haspopup="true" className={this.isActive({ url: AppRoutes.Dashboard.Profile }) ? "appHeader__tab active" : "appHeader__tab"} onKeyDown={this.onKeyDownHandler} onClick={this.routeToProfilePage}>
                                <i className="icon icon-Profil"></i>
                                {i18n.t('profilePage.myDataTitle')}
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        )
    }
}

const mapStateToProps = () => { };

const actions = {
    actionGetSubsystems,
    actionChangeOib
};

export default withRouter(connect(
    mapStateToProps,
    actions
)(AppHeader))