import { Observable } from "rxjs";
import { SubsystemInfoInterface } from "../interfaces/subsystemInfo/subsystemInfo.interface";
import HttpFactory from "./abstract.api.service";
import { ApiRoutes } from './api.routes';
import { UserInterface } from "..";
import { AuthFormData } from "../redux/e-business/e-business.reducer";

export class SubsystemService {
    static getSubsystemById(categoryId: number): Observable<Array<SubsystemInfoInterface>> {
        return HttpFactory.GET(ApiRoutes.GetApplicationsByCategoryId(categoryId));
    }
    static getSubsystems(lang: string): Observable<Array<SubsystemInfoInterface>> {
        return HttpFactory.GET(`${ApiRoutes.GetApplications}?lang=${lang}`);
    }
}

export class SubjectService {
    static getSubjectByOIB(oib: string, token?: string): Observable<UserInterface> {
        return HttpFactory.GET(ApiRoutes.GetUserByOIB(oib), undefined, token);
    }

    static geteAuthorizationSubject(ForLegalIps: string, ForLegalIzvor_reg: string, ToLegalIps: string, ToLegalIzvor_reg: string, token?: string): Observable<UserInterface> {
        return HttpFactory.GET(ApiRoutes.GeteAuthorizationUserByOIB(ForLegalIps, ForLegalIzvor_reg, ToLegalIps, ToLegalIzvor_reg), undefined, token);
    }

    static patchSubjectByOIB(oib: string, user: UserInterface, token?: string): Observable<UserInterface> {
        return HttpFactory.PATCH(ApiRoutes.GetUserByOIB(oib), user, undefined, token);
    }

    static createSubjectByOIB(oib: string, token?: string): Observable<UserInterface> {
        return HttpFactory.POST(ApiRoutes.GetUserByOIB(oib), {}, undefined, token);
    }

    static sendEmailVerification(oib: string, email: string, token: string, ForLegalIzvor_reg?: string): Observable<UserInterface> {
        return HttpFactory.POST(ApiRoutes.SendEmailVerification, { email, oib, ForLegalIzvor_reg }, undefined, token);
    }

    static verifyEmail(oib: string, email: string, code: string, token: string, ForLegalIzvor_reg?: string): Observable<UserInterface> {
        return HttpFactory.POST(ApiRoutes.VerifyEmail, { email, oib, code, ForLegalIzvor_reg }, undefined, token);
    }

    static deleteEmail(oib: string, token: string, ForLegalIzvor_reg?: string): Observable<UserInterface> {
        return HttpFactory.POST(ApiRoutes.DeleteEmail(oib, ForLegalIzvor_reg), {}, undefined, token);
    }

    static disableEmailPopUp(oib: string, token: string, ForLegalIzvor_reg?: string): Observable<UserInterface> {
        return HttpFactory.POST(ApiRoutes.DisableEmailPopUp(oib, ForLegalIzvor_reg), {}, undefined, token);
    }
}

export class KeycloackService {
    static inspectSession(username: string): Observable<any> {
        return HttpFactory.POST_URLENCODED(ApiRoutes.SessionInspector, { username: username });
    }
}

export class AuthorizationService {
    static getAuthorizationFormData(requestId: string): Observable<any> {
        return HttpFactory.GET(ApiRoutes.GetAuthorizationFormData(encodeURIComponent(requestId)), undefined);
    }

    static getPermissions(oib: string): Observable<any> {
        return HttpFactory.GET(ApiRoutes.GetPermissions(oib), undefined);
    }

    static grantAccess(data: AuthFormData, activeRoles: any): Observable<any> {
        const roles = [] as any;
        activeRoles.forEach((role: any) => {
            roles.push(...role.dozvole.map((doz: any) => ({ key: doz.key, description: doz.description, value: doz.value ?? false, valueDescription: '' })));
        });

        return HttpFactory.POST(ApiRoutes.GrantAccess, { ...data, roles }, undefined, undefined);
    }
}