import { Dialog, Button } from '@material-ui/core';
import React, { useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { actionGetSubject, AuthStateInterface, StoreStateInterface } from '../../../../common/redux';
import { connect } from 'react-redux';
import './email-subscription.styles.scss';
import { i18n, UserInterface } from '../../../../common';

type ConfirmVerificationProps = {
    actionGetSubject: (user: UserInterface) => void;
    auth: AuthStateInterface;
}

const ConfirmVerificationComponent = (props: ConfirmVerificationProps) => {
    const [isOpen, setIsOpen] = useState(true);

    return (
        <Dialog open={isOpen} onClose={() => setIsOpen(false)} disableAutoFocus>
            <div className="dialog__top-container">
                <div className="dialog__top-container-title">
                    {i18n.t('email.emailVerificationSuccessTitle')}
                </div>
                <div className="dialog__top-container-icon" onClick={() => setIsOpen(false)}>
                    <CloseIcon />
                </div>
            </div>
            <div className="dialog__bottom-container">
                <div className="dialog__bottom-container-description">
                    {i18n.t('email.emailVerificationSuccessDescription')}
                </div>
                <Button
                    className="dialog__bottom-container-accept-button"
                    onClick={() => {
                        setIsOpen(false);
                        props.actionGetSubject(props.auth.user!);
                    }}
                >
                    {i18n.t('email.done')}
                </Button>
            </div>
        </Dialog>
    )
}

const mapStateToProps = (state: StoreStateInterface) => {
    return {
        auth: state.auth
    }
}

const actions = {
    actionGetSubject
};

export const ConfirmVerification = connect(
    mapStateToProps,
    actions
)(ConfirmVerificationComponent);