import React, { Component } from "react";
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';


export class Alert extends Component<AlertProps> {
  render() {
      return (
        <MuiAlert elevation={6} variant="filled" {...this.props} />
      )
  }
}