import { Dispatch } from "redux";
import { SubsystemsConstant } from './subSystems.constant';
import { SubsystemService } from '../../api/api.service';
import { SubsystemInfoInterface } from "../../interfaces";

export function actionGetSubsystems(lang: string) {
    return (dispatch: Dispatch) => {
        dispatch({ type: SubsystemsConstant.GET_SUBSYSTEMS_START });
        SubsystemService.getSubsystems(lang).subscribe((response: Array<SubsystemInfoInterface>) => {
            if (!!response) {
                let agronetUrl = response.find((system: SubsystemInfoInterface) => system?.naziv?.includes("Agronet"))?.url;
                if (!!agronetUrl) {
                    localStorage.setItem("agronetUrl", agronetUrl);
                }
            }
            dispatch({ type: SubsystemsConstant.GET_SUBSYSTEMS_SUCCESS, payload: response })
        }, (error: any) => {
                if (!error.message.includes("Failed to")) {
                    dispatch({ type: SubsystemsConstant.GET_SUBSYSTEMS_ERROR, payload: error.message })
                }
        })
    };
}
