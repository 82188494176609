import './profile.styles.scss';
import React from "react";
import { actionRevoke, StoreStateInterface, actionBlockUI, actionEnablePopUp, ScrollToNav, AuthStateInterface, ScrollToNavLinkInterface, UserInterface, DjelatnostPogona, SubsystemInfoInterface, SubystemsStateInterface, DBConstants, actionGetSubsystems, Loading, actionGetSubject } from "../../../../common";
import { connect } from "react-redux";
import { withKeycloak } from '@react-keycloak/web'
import { Element } from 'react-scroll';
import Grid, { GridSize } from '@material-ui/core/Grid';

import moment from 'moment';
import { KeycloakInstance } from 'keycloak-js';
import { EmailSubscription } from '../applications/email-subscription.component';
import { EmailInput } from './email-input.component';
import i18n from '../../../../common/services/translate.service';


type PropsType = {
    auth: AuthStateInterface;
    actionBlockUI: () => void;
    actionRevoke: (user: UserInterface) => void;
    keycloakInitialized: boolean;
    keycloak: KeycloakInstance;
    subsystems: SubystemsStateInterface;
    actionGetSubsystems: (lang: string) => void;
    actionGetSubject: (user: UserInterface) => void;
    actionEnablePopUp: () => void;
};

interface State {
    showText: boolean;
    hasAnyRegisteredSystems: boolean;
}

const GridItem = ({ value, label, xs = 6, sm = 3 }: { value: string | undefined, label: string, xs?: GridSize, sm?: GridSize }) =>
    <Grid item xs={xs} sm={sm}>
        <label className="profile__label">{label}</label>
        <div className="profile__value">{value}</div>
    </Grid>;

class ProfileComponent extends React.Component<PropsType, State> {
    constructor(props: PropsType) {
        super(props);
        this.state = {
            showText: false,
            hasAnyRegisteredSystems: true,
        }
    }

    componentDidMount() {
        this.props.actionGetSubsystems(window.localStorage.getItem("lang") !== null ? window.localStorage.getItem("lang")! : "hr");
    }

    private generateNavLinks(): Array<ScrollToNavLinkInterface> {
        let array = [
        ];
        if (this.props.auth.user?.oibRegistar !== null) {
            array.push({ to: "profile", title: i18n.t('profilePage.myDataTitle') });
        }
        if (this.props.auth.user?.obrtniRegistar !== null) {
            array.push({ to: "obrtniRegistar", title: i18n.t('profilePage.craftRegisterTitle') });
        }
        if (this.props.auth.user?.sudskiRegistar !== null) {
            array.push({ to: "sudskiRegistar", title: i18n.t('profilePage.courtRegisterTitle') });
        }
        if (this.props.auth.user?.rkpfiRegistar?.length !== 0) {
            array.push({ to: "rkpfiRegistar", title: i18n.t('profilePage.rkpfiRegisterTitle') });
        }
        if (this.props.auth.user?.opgRegistar?.length !== 0) {
            array.push({ to: "opgRegistar", title: i18n.t('profilePage.opgRegisterTitle') });
        }
        if (this.props.auth.user?.registarUdruga !== null) {
            array.push({ to: "registarUdruga", title: i18n.t('profilePage.associationRegisterTitle') });
        }
        return array;
    }

    private renderOib(status: string | undefined): string {
        switch (status) {
            case "01": return "Aktivan";
            case "02": return "Neaktivan";
            case "03": return "Poništen";
            default: return "";
        }
    }

    private djelatnostiPogona(data: Array<DjelatnostPogona>): string {
        let text: Array<string> = [];
        data.map((djelatnost: DjelatnostPogona, index: number) => {
            if (djelatnost.naziv !== null) {
                text.push(djelatnost.naziv);
            }
            return djelatnost;
        })
        return text.join(', ');
    }

    private handleNull(value: string | null | undefined) {
        if (value !== undefined && value !== null) {
            return value;
        } else {
            return "";
        }
    }

    private predmetiPoslovanja(data: Array<{
        id: string;
        rbrDjelatnosti: string;
        sifraDjelatnosti?: string;
        djelatnostTekst: string;
        aktivan: string;
        datumKreiranja: string;
        datumIzmjene: string;
    }>): string {
        let text: Array<string> = [];
        data.map((predmet: {
            id: string;
            rbrDjelatnosti: string;
            sifraDjelatnosti?: string;
            djelatnostTekst: string;
            aktivan: string;
            datumKreiranja: string;
            datumIzmjene: string;
        }, index: number) => {
            if (predmet.djelatnostTekst !== null) {
                text.push(predmet.djelatnostTekst)
            }
            return predmet;
        })
        return text.join(", ");
    }

    renderStanjeObrta(stanje: string | undefined): string {
        switch (stanje) {
            case "1": return "U radu";
            case "2": return "Odjava";
            case "3": return "Privremena obustava";
            case "4": return "Mirovanje";
            case "5": return "Preseljen";
            case "6": return "Bez početka";
            case "7": return "Odustanak";
            default: return "";
        }
    }

    renderBudgetUserStatus(status: number | undefined): string {
        switch (status) {
            case 1: return "Aktivan";
            case 2: return "Izbrisan";
            case 3: return "Neaktivan";
            default: return "";
        }
    }

    render() {
        if (this.props.subsystems.isLoading && this.props.subsystems.data === undefined) {
            return <Loading isLoading />
        }
        const registeredSubsystems = this.props.subsystems.data?.filter((data: SubsystemInfoInterface) => data.aktivan).filter((data: SubsystemInfoInterface) => {
            let find = this.props.auth.user?.pravaPristupa?.find((pravo: any) => pravo.podsustavId === data.id && pravo.aktivan === true);
            return find !== undefined && !DBConstants.PlaviDizelId.includes(data.id);
        });
        const hasNoRegisteredSystems = registeredSubsystems === undefined || registeredSubsystems === null || registeredSubsystems?.length === 0;
        if (hasNoRegisteredSystems) {
            return (
                <div className="nosystems">
                    <div className="nosystems__text">{i18n.t("profilePage.notRegistered")}</div>
                </div>
            );
        }
        const oibRegOstaliPodaci = JSON.parse(this.props.auth.user?.oibRegistar?.ostaliPodaci || '{}');
        const dodatakAdresi = !!oibRegOstaliPodaci?.AdresaPrebivalista?.KucniBrojDodatak ? oibRegOstaliPodaci?.AdresaPrebivalista?.KucniBrojDodatak : '';
        return (
            <article className="profile">
                <EmailSubscription />
                <section className="profile__left">
                    {(!!this.props.auth.user?.oibRegistar && this.props.auth.user?.oibRegistar !== null) &&
                        <Element name="profile">
                            <div className="title title--green" id="profile">{i18n.t('profilePage.myDataTitle')}</div>
                            <div className="profile__card">
                                <Grid container spacing={3}>
                                    <GridItem label={i18n.t('profilePage.myData.firstName')} value={this.props.auth.user?.oibRegistar?.ime} />
                                    <GridItem label={i18n.t('profilePage.myData.lastName')} value={this.props.auth.user?.oibRegistar?.prezime} />
                                    <GridItem label={i18n.t('profilePage.myData.oib')} value={this.props.auth.user?.oib} />
                                    <GridItem label={i18n.t('profilePage.myData.oibStatus')} value={this.renderOib(this.props.auth.user?.oibRegistar?.oiBstatus)} />
                                </Grid>
                                <Grid container spacing={3}>
                                    <GridItem label={i18n.t('profilePage.myData.gender')} value={this.props.auth.user?.oibRegistar?.spol} />
                                    <GridItem label={i18n.t('profilePage.myData.dateOfBirth')} value={moment(this.props.auth.user?.oibRegistar?.datumRodjenja).format("DD.MM.YYYY.")} />
                                    <GridItem label={i18n.t('profilePage.myData.placeOfBirth')} value={`${this.props.auth.user?.oibRegistar?.mjestoRodjenja} ${this.props.auth.user?.oibRegistar?.drzavaRodjenja}`} />
                                </Grid>
                                <Grid container spacing={3}>
                                    <GridItem label={i18n.t('profilePage.myData.address')} value={`${this.props.auth.user?.oibRegistar?.ulica}, ${this.props.auth.user?.oibRegistar?.kucniBroj + ' ' + dodatakAdresi}`} />
                                    <GridItem label={i18n.t('profilePage.myData.city')} value={this.props.auth.user?.oibRegistar?.naselje} />
                                    <GridItem label={i18n.t('profilePage.myData.country')} value={this.props.auth.user?.oibRegistar?.drzava} />

                                </Grid>
                                <EmailInput auth={this.props.auth} onGetSubject={() => this.props.actionGetSubject(this.props.auth.user!)} onPopUpEnable={() => this.props.actionEnablePopUp()} />
                            </div>
                        </Element>
                    }
                    {(!!this.props.auth.user?.obrtniRegistar && this.props.auth.user?.obrtniRegistar !== null) &&
                        <Element name="obrtniRegistar">
                            <div className="title title--green" id="obrtniRegistar">{i18n.t('profilePage.craftRegisterTitle')}</div>
                            <div className="profile__card">
                                <Grid container spacing={3}>
                                    <GridItem label={i18n.t('profilePage.craftRegister.owner')} value={this.props.auth.user?.obrtniRegistar?.vlasnik} />
                                    <GridItem label={i18n.t('profilePage.craftRegister.MBO')} value={this.props.auth.user?.obrtniRegistar?.mbo} />
                                    <GridItem label={i18n.t('profilePage.craftRegister.craftNumber')} value={this.props.auth.user?.obrtniRegistar?.brojObrtnice} />
                                    <GridItem label={i18n.t('profilePage.craftRegister.craftState')} value={this.renderStanjeObrta(this.props.auth.user?.obrtniRegistar?.stanjeObrta)} />
                                    <GridItem label={i18n.t('profilePage.craftRegister.startDate')} value={moment(this.props.auth.user?.obrtniRegistar?.datumPocetkaObrta).format("DD.MM.YYYY.")} />
                                    <GridItem label={i18n.t('profilePage.craftRegister.endDate')} value={moment(this.props.auth.user?.obrtniRegistar?.datumPrestankaObrta).format("DD.MM.YYYY.")} />
                                </Grid>
                                {
                                    this.props.auth.user?.obrtniRegistar?.sjediste &&
                                    <React.Fragment >
                                        <Grid container spacing={3}>
                                            <GridItem label={i18n.t('profilePage.craftRegister.craftName')} value={this.props.auth.user?.obrtniRegistar?.sjediste.nazivPogona} />
                                            <GridItem label={i18n.t('profilePage.craftRegister.shortName')} value={this.props.auth.user?.obrtniRegistar?.sjediste.kratkiNazivPogona} />
                                            <GridItem label={i18n.t('profilePage.craftRegister.county')} value={this.props.auth.user?.obrtniRegistar?.sjediste.zupanija} />
                                            <GridItem label={i18n.t('profilePage.craftRegister.city')} value={this.props.auth.user?.obrtniRegistar?.sjediste.nazivNaselja} />
                                            <GridItem label={i18n.t('profilePage.craftRegister.address')} value={`${this.props.auth.user?.obrtniRegistar?.sjediste.ulica}, ${this.props.auth.user?.obrtniRegistar?.sjediste.kucniBroj}`} />
                                            <GridItem
                                                sm={6}
                                                label={i18n.t('profilePage.craftRegister.businessActivities')}
                                                value={this.props.auth.user?.obrtniRegistar?.sjediste.djelatnostiPogona && this.djelatnostiPogona(this.props.auth.user?.obrtniRegistar?.sjediste.djelatnostiPogona)}
                                            />
                                        </Grid>
                                    </React.Fragment>
                                }
                            </div>
                        </Element>
                    }
                    {(!!this.props.auth.user?.sudskiRegistar && this.props.auth.user?.sudskiRegistar !== null) &&
                        <Element name="sudskiRegistar">
                            <div className="title title--green" id="sudskiRegistar">{i18n.t('profilePage.courtRegisterTitle')}</div>
                            <div className="profile__card">
                                <Grid container spacing={3}>
                                    <GridItem xs={12} sm={12} label={i18n.t('profilePage.courtRegister.company')} value={this.props.auth.user?.sudskiRegistar?.naziv} />
                                    <GridItem xs={6} sm={3} label={i18n.t('profilePage.courtRegister.mbs')} value={this.props.auth.user?.sudskiRegistar?.mbs} />
                                    <GridItem xs={6} sm={3} label={i18n.t('profilePage.courtRegister.oib')} value={this.props.auth.user?.oib} />
                                </Grid>
                                <Grid container spacing={3}>
                                    <GridItem xs={12} sm={12} label={i18n.t('profilePage.courtRegister.headquaters')} value={`${this.handleNull(this.props.auth.user?.sudskiRegistar?.sjedisteUlica)} ${this.handleNull(this.props.auth.user?.sudskiRegistar?.sjedisteBroj)}, ${this.handleNull(this.props.auth.user?.sudskiRegistar?.sjedisteNaselje)}, ${this.handleNull(this.props.auth.user?.sudskiRegistar?.sjedisteOpcina)} ${this.handleNull(this.props.auth.user?.sudskiRegistar?.sjedisteDrzava)}`} />
                                </Grid>
                                <Grid container spacing={3}>
                                    <GridItem xs={4} sm={3} label={i18n.t('profilePage.courtRegister.shareCapital')} value={this.props.auth.user?.sudskiRegistar?.temeljniKapital} />
                                    <GridItem xs={8} sm={9} label={i18n.t('profilePage.courtRegister.legalForm')} value={this.props.auth.user?.sudskiRegistar?.pravniOblik} />
                                    <GridItem xs={12} sm={12} label={i18n.t('profilePage.courtRegister.businessScope')} value={this.props.auth.user?.sudskiRegistar?.predmetiPoslovanja &&
                                        this.predmetiPoslovanja(this.props.auth.user?.sudskiRegistar?.predmetiPoslovanja!)} />
                                </Grid>
                            </div>
                        </Element>
                    }
                    {(!!this.props.auth.user?.rkpfiRegistar && this.props.auth.user?.rkpfiRegistar?.length !== 0) &&
                        <Element name="rkpfiRegistarTitle" id="rkpfiRegistar">
                            <div className="title title--green">{i18n.t('profilePage.rkpfiRegisterTitle')}</div>
                        </Element>
                    }
                    {(!!this.props.auth.user?.rkpfiRegistar && this.props.auth.user?.rkpfiRegistar?.length === 1) ?
                        (
                            !!this.props.auth.user?.rkpfiRegistar && this.props.auth.user?.rkpfiRegistar?.map((item, index) => (
                                <Element key={index} name={`rkpfiRegistar`}>
                                    <div className="profile__card">
                                        <div className="profile__value" style={{ textDecoration: "underline" }} >{i18n.t('profilePage.rkpfiRegister.budgetUserTitle')}</div>
                                        <Grid container spacing={3}>
                                            <GridItem xs={5} sm={5} label={i18n.t('profilePage.rkpfiRegister.budgetUserName')} value={item.budgetUserName} />
                                            <GridItem xs={2} sm={2} label={i18n.t('profilePage.rkpfiRegister.budgetUserOib')} value={item.budgetUserOib} />
                                            <GridItem xs={2} sm={2} label={i18n.t('profilePage.rkpfiRegister.budgetUserMB')} value={item.budgetUserMB} />
                                            <GridItem xs={2} sm={2} label={i18n.t('profilePage.rkpfiRegister.rkpCode')} value={item.rkpCode} />
                                            <GridItem xs={1} sm={1} label={i18n.t('profilePage.rkpfiRegister.budgetUserStatus')} value={this.renderBudgetUserStatus(item.budgetUserStatus)} />
                                        </Grid>
                                        <div className="profile__value" style={{ textDecoration: "underline" }} >{i18n.t('profilePage.rkpfiRegister.representativeTitle')}</div>
                                        <Grid container spacing={1}>
                                            <GridItem xs={5} sm={5} label={i18n.t('profilePage.rkpfiRegister.representativeFunction')} value={item.representativeFunction} />
                                            <GridItem xs={4} sm={4} label={i18n.t('profilePage.rkpfiRegister.representativeNameAndSurname')} value={item.representativeNameAndSurname} />
                                            <GridItem xs={3} sm={3} label={i18n.t('profilePage.rkpfiRegister.representativeOib')} value={item.representativeOib} />
                                        </Grid>
                                    </div>
                                </Element>))) :
                        (
                            !!this.props.auth.user?.rkpfiRegistar && this.props.auth.user?.rkpfiRegistar?.map((item, index) => (
                                <Element key={index} name={`rkpfiRegistar-${index}`}>
                                    <div className="profile__card">
                                        <div className="profile__value" style={{ textDecoration: "underline" }} >{i18n.t('profilePage.rkpfiRegister.budgetUserTitle')}</div>
                                        <Grid container spacing={3}>
                                            <GridItem xs={6} sm={6} label={i18n.t('profilePage.rkpfiRegister.budgetUserName')} value={item.budgetUserName} />
                                            <GridItem xs={2} sm={2} label={i18n.t('profilePage.rkpfiRegister.budgetUserOib')} value={item.budgetUserOib} />
                                            <GridItem xs={2} sm={2} label={i18n.t('profilePage.rkpfiRegister.budgetUserMB')} value={item.budgetUserMB} />
                                            <GridItem xs={2} sm={2} label={i18n.t('profilePage.rkpfiRegister.rkpCode')} value={item.rkpCode} />
                                            <GridItem xs={1} sm={1} label={i18n.t('profilePage.rkpfiRegister.budgetUserStatus')} value={this.renderBudgetUserStatus(item.budgetUserStatus)} />
                                        </Grid>
                                        <Grid container spacing={1}>
                                            <GridItem xs={12} sm={12} label={i18n.t('profilePage.rkpfiRegister.representativeFunction')} value={item.representativeFunction} />
                                        </Grid>
                                    </div>
                                </Element>)
                            ))}
                    {(!!this.props.auth.user?.opgRegistar && this.props.auth.user?.opgRegistar?.length !== 0) &&
                        <Element name="opgRegistarTitle">
                            <div className="title title--green" id="opgRegistar">{i18n.t('profilePage.opgRegisterTitle')}</div>
                        </Element>
                    }
                    {
                        !!this.props.auth.user?.opgRegistar && this.props.auth.user?.opgRegistar?.map((item, index) => (

                            <Element key={index} name={`opgRegistar-${index}`} >
                                <div className="profile__card">
                                    <Grid container spacing={3}>
                                        <GridItem label={i18n.t('profilePage.opgRegister.pgName')} value={item.nazivPG} />
                                        <GridItem label={i18n.t('profilePage.opgRegister.shortName')} value={item.shortName} />
                                        <GridItem label={i18n.t('profilePage.opgRegister.opgStatus')} value={item.statusOPGa} />
                                        <GridItem label={i18n.t('profilePage.opgRegister.mibpg')} value={item.mibpg} />
                                        <GridItem label={i18n.t('profilePage.opgRegister.pgType')} value={item.tipPG} />
                                        <GridItem label={i18n.t('profilePage.opgRegister.pgStartDate')} value={moment(item.datumPocetkaPG).format("DD.MM.YYYY.")} />
                                        <GridItem label={i18n.t('profilePage.opgRegister.pgEndDate')} value={moment(item.datumKrajaPG).isAfter('01.01.0001') ? moment(item.datumKrajaPG).format("DD.MM.YYYY.") : ""} />
                                        <GridItem label={i18n.t('profilePage.opgRegister.farmOIB')} value={item.farmOIB} />
                                        <GridItem sm={6} label={i18n.t('profilePage.opgRegister.status')} value={item.status} />
                                        <GridItem label={i18n.t('profilePage.opgRegister.mbo')} value={item.maticniBroj} />
                                    </Grid>
                                    <div className="profile__value" style={{ textDecoration: "underline" }} >{i18n.t('profilePage.opgRegister.address')}</div>
                                    <Grid container spacing={3}>
                                        <GridItem label={i18n.t('profilePage.opgRegister.street')} value={item.ulica} />
                                        <GridItem label={i18n.t('profilePage.opgRegister.settlement')} value={item.naselje} />
                                        <GridItem label={i18n.t('profilePage.opgRegister.city')} value={item.grad} />
                                        <GridItem label={i18n.t('profilePage.opgRegister.zipCode')} value={item.postBroj} />
                                        <GridItem label={i18n.t('profilePage.opgRegister.county')} value={item.zupanija} />
                                    </Grid>
                                    <div className="profile__value" style={{ textDecoration: "underline" }} >{i18n.t('profilePage.opgRegister.contactInfo')}</div>
                                    <Grid container spacing={3}>
                                        <GridItem label={i18n.t('profilePage.opgRegister.email')} value={item.email} />
                                        <GridItem label={i18n.t('profilePage.opgRegister.telephone')} value={item.telefon} />
                                        <GridItem label={i18n.t('profilePage.opgRegister.mobile')} value={item.mobitel} />
                                    </Grid>
                                </div>
                            </Element>))
                    }
                    {(!!this.props.auth.user?.registarUdruga && this.props.auth.user?.registarUdruga !== null) &&
                        <Element name={`associationRegisterTitle`} >
                            <div className="title title--green" id="registarUdruga">{i18n.t('profilePage.associationRegisterTitle')}</div>
                                <div className="profile__card">
                                    <Grid container spacing={3}>
                                        <GridItem xs={5} sm={5} label={i18n.t('profilePage.associationRegister.name')} value={this.props.auth.user?.registarUdruga?.naziv} />
                                        <GridItem xs={3} sm={3} label={i18n.t('profilePage.associationRegister.shortName')} value={this.props.auth.user?.registarUdruga?.skraceniNaziv} />
                                        <GridItem xs={2} sm={2} label={i18n.t('profilePage.associationRegister.oib')} value={this.props.auth.user?.registarUdruga?.oib} />
                                        <GridItem xs={2} sm={2} label={i18n.t('profilePage.associationRegister.registryNumber')} value={this.props.auth.user?.registarUdruga?.registarskiBroj} />
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <GridItem xs={5} sm={5} label={i18n.t('profilePage.associationRegister.headquaters')} value={this.props.auth.user?.registarUdruga?.sjediste} />
                                        <GridItem xs={3} sm={3} label={i18n.t('profilePage.associationRegister.county')} value={this.props.auth.user?.registarUdruga?.zupanija} />
                                        <GridItem xs={2} sm={2} label={i18n.t('profilePage.associationRegister.dateOfRegistration')} value={moment(this.props.auth.user?.registarUdruga?.datumUpisa).isAfter('01.01.1000') ? moment(this.props.auth.user?.registarUdruga?.datumUpisa).format("DD.MM.YYYY.") : "-"} />
                                        <GridItem xs={2} sm={2} label={i18n.t('profilePage.associationRegister.foundingDate')} value={moment(this.props.auth.user?.registarUdruga?.datumOsnivackeSkupine).format("DD.MM.YYYY.")} />
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <GridItem xs={5} sm={5} label={i18n.t('profilePage.associationRegister.formOfAssociation')} value={this.props.auth.user?.registarUdruga?.oblikUdruzivanja} />
                                        <GridItem xs={3} sm={3} label={i18n.t('profilePage.associationRegister.status')} value={this.props.auth.user?.registarUdruga?.status} />
                                        <GridItem xs={2} sm={2} label={i18n.t('profilePage.associationRegister.statusDate')} value={moment(this.props.auth.user?.registarUdruga?.datumStatusa).isAfter('01.01.1000') ? moment(this.props.auth.user?.registarUdruga?.datumStatusa).format("DD.MM.YYYY.") : "-"} />
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <GridItem xs={12} sm={12} label={i18n.t('profilePage.associationRegister.goals')} value={this.props.auth.user?.registarUdruga?.ciljevi} />
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <GridItem xs={12} sm={12} label={i18n.t('profilePage.associationRegister.targetGroups')} value={this.props.auth.user?.registarUdruga?.ciljaneSkupine} />
                                    </Grid>
                                    <Grid container spacing={3}>
                                      <GridItem xs={12} sm={12} label={i18n.t('profilePage.associationRegister.businessDescription')} value={this.props.auth.user?.registarUdruga?.opisDjelatnosti} />
                                    </Grid>
                                    <Grid container spacing={3}>
                                    <GridItem xs={12} sm={12} label={i18n.t('profilePage.associationRegister.economicActivites')} value={this.props.auth.user?.registarUdruga?.gospodarskeDjelatnosti} />
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <GridItem xs={5} sm={5} label={i18n.t('profilePage.associationRegister.nameInOtherLanguage')} value={this.props.auth.user?.registarUdruga?.nazivNaDrugimJezicima} />
                                        <GridItem xs={3} sm={3} label={i18n.t('profilePage.associationRegister.shortNameInOtherLanguage')} value={this.props.auth.user?.registarUdruga?.skrNazivNaDrugimJezicima} />
                                        <GridItem xs={2} sm={2} label={i18n.t('profilePage.associationRegister.email')} value={this.props.auth.user?.registarUdruga?.mail} />
                                        <GridItem xs={2} sm={2} label={i18n.t('profilePage.associationRegister.webPage')} value={this.props.auth.user?.registarUdruga?.webStranica} />
                                </Grid>
                                <div className="profile__value" style={{ textDecoration: "underline" }} >{i18n.t('profilePage.associationRegister.persons')}</div>
                                <Grid container >
                                    <GridItem xs={2} sm={2} label={i18n.t('profilePage.associationRegister.personName')} value={undefined} />
                                    <GridItem xs={2} sm={2} label={i18n.t('profilePage.associationRegister.surname')} value={undefined} />
                                    <GridItem xs={2} sm={2} label={i18n.t('profilePage.associationRegister.oib')} value={undefined} />
                                    <GridItem xs={2} sm={2} label={i18n.t('profilePage.associationRegister.role')} value={undefined} />
                                    <GridItem xs={2} sm={2} label={i18n.t('profilePage.associationRegister.type')} value={undefined} />
                                    <GridItem xs={2} sm={2} label={i18n.t('profilePage.associationRegister.function')} value={undefined} />
                                </Grid>
                                {
                                    this.props.auth.user?.registarUdruga?.osobe?.map((item, index) => (

                                        <Grid container>
                                            <Grid item xs={2} sm={2}>
                                                <div className="profile__value">{item.ime}</div>
                                            </Grid>
                                            <Grid item xs={2} sm={2}>
                                                <div className="profile__value">{item.prezime}</div>
                                            </Grid>
                                            <Grid item xs={2} sm={2}>
                                                <div className="profile__value">{item.oib == "" ? "-": item.oib} </div>
                                            </Grid>
                                            <Grid item xs={2} sm={2}>
                                                <div className="profile__value">{item.svojstvo == "" ? "-" : item.svojstvo}</div>
                                            </Grid>
                                            <Grid item xs={2} sm={2}>
                                                <div className="profile__value">{item.vrstaOsobe}</div>
                                            </Grid>
                                            <Grid item xs={2} sm={2}>
                                                <div className="profile__value">{item.funkcija}</div>
                                            </Grid>
                                        </Grid>))
                                }
                                </div>
                            </Element>
                    }
                </section>
                <ScrollToNav smallOffset className="profile__right"
                    navLinks={this.generateNavLinks()}
                ></ScrollToNav>
            </article>
        );
    }
}

const mapStateToProps = (state: StoreStateInterface) => {
    return {
        auth: state.auth,
        subsystems: state.subsystems
    }
}

const actions = {
    actionBlockUI,
    actionRevoke,
    actionGetSubsystems,
    actionGetSubject,
    actionEnablePopUp
};

const redux = connect(
    mapStateToProps,
    actions
)(ProfileComponent);

export default withKeycloak(redux)