export const LinksConstant = {
    ministarstvo: "https://poljoprivreda.gov.hr/",
    egradani: "https://gov.hr/",
    osustavu: "/",
    kontakt: "/",
    eu: "https://europa.eu/next-generation-eu/index_en",
}

export const DBConstants = {
    PlaviDizelId: [10, 11, 20, 26]
}