import React from 'react';
import { SubjectService } from '../../../../common';
import { StoreStateInterface, actionGetSubject, actionEnablePopUp } from '../../../../common/redux';
import { connect } from 'react-redux';
import './email-subscription.styles.scss';
import { SubmitEmail } from './submit-email.component';
import { SubmitVerificationCode } from './submit-verification-code.component';
import { ConfirmVerification } from './confirm-verification.component';
import queryString from "query-string";

const EmailSubscriptionComponent = (props: any) => {
    const { user } = props.auth;
    if (user?.isEmailPopUpDisabled) {
        return <></>;
    }

    if (user?.email && user?.isEmailVerified) {
        return <ConfirmVerification />;
    }

    if (!user?.email) {
        return (
            <SubmitEmail
                onEmailSubmit={(data) => {
                    const result = queryString.parse(window.location.search);
                    const { ForLegalIzvor_reg = undefined, ForLegalIps = undefined } = result;
                    let identifier = '';
                    if (ForLegalIps !== undefined && ForLegalIps !== '') {
                        identifier = ForLegalIps as string;
                    }
                    else {
                        identifier = encodeURIComponent(props.auth.user.oib);
                    }
                    SubjectService.sendEmailVerification(identifier, data.email, props.auth.token, ForLegalIzvor_reg as string).subscribe((data) => {
                        props.actionGetSubject(props.auth.user);
                    })
                }}
                onPopUpClose={() => {
                    const result = queryString.parse(window.location.search);
                    const { ForLegalIzvor_reg = undefined, ForLegalIps = undefined } = result;
                    let identifier = '';
                    if (ForLegalIps !== undefined && ForLegalIps !== '') {
                        identifier = ForLegalIps as string;
                    }
                    else {
                        identifier = encodeURIComponent(props.auth.user.oib);
                    }
                    SubjectService.disableEmailPopUp(identifier, props.auth.token, ForLegalIzvor_reg as string).subscribe((data) => {
                        props.actionGetSubject(props.auth.user);
                    })
                }}
            />
        )
    }


    if (user?.email && !user?.isEmailVerified) {
        return (
            <SubmitVerificationCode
                email={props.auth.user.email}
                onEmailReset={() => {
                    const result = queryString.parse(window.location.search);
                    const { ForLegalIzvor_reg = undefined, ForLegalIps = undefined } = result;
                    let identifier = '';
                    if (ForLegalIps !== undefined && ForLegalIps !== '') {
                        identifier = ForLegalIps as string;
                    }
                    else {
                        identifier = encodeURIComponent(props.auth.user.oib);
                    }
                    SubjectService.deleteEmail(identifier, props.auth.token, ForLegalIzvor_reg as string).subscribe((data) => {
                        props.actionGetSubject(props.auth.user);
                    });
                }}
                onVerificationCodeSubmit={(data) => {
                    const result = queryString.parse(window.location.search);
                    const { ForLegalIzvor_reg = undefined, ForLegalIps = undefined } = result;
                    let identifier = '';
                    if (ForLegalIps !== undefined && ForLegalIps !== '') {
                        identifier = ForLegalIps as string;
                    }
                    else {
                        identifier = encodeURIComponent(user?.oib);
                    }
                    SubjectService.verifyEmail(identifier, user?.email!, data.verificationCode, props.auth.token, ForLegalIzvor_reg as string).subscribe(
                        res => {
                            props.actionGetSubject(props.auth.user);
                            setTimeout(() => {
                                props.actionEnablePopUp();
                            }, 1000);
                        },
                        err => console.error(err)
                    );
                }}
                onVerificationCodeResend={() => {
                    const result = queryString.parse(window.location.search);
                    const { ForLegalIzvor_reg = undefined, ForLegalIps = undefined } = result;
                    let identifier = '';
                    if (ForLegalIps !== undefined && ForLegalIps !== '') {
                        identifier = ForLegalIps as string;
                    }
                    else {
                        identifier = encodeURIComponent(props.auth.user.oib);
                    }
                    SubjectService.sendEmailVerification(identifier, user?.email!, props.auth.token, ForLegalIzvor_reg as string).subscribe((data) => {
                    })
                }}
                onPopUpClose={() => {
                    props.actionGetSubject(props.auth.user);
                }}
            />
        );
    }
    return <></>;
}

const mapStateToProps = (state: StoreStateInterface) => {
    return {
        auth: state.auth
    }
}

const emailActions = {
    actionGetSubject,
    actionEnablePopUp
};

export const EmailSubscription = connect(
    mapStateToProps,
    emailActions
)(EmailSubscriptionComponent);