import { RouteInterface } from "../../../common/interfaces/framework/routing.interface";
import LandingNavigationRoutingComponent from "../../landing/navigation/landing.routing.component";
import { AppRoutes, RouteProtectionLevel } from "../../../common";
import DashboardRoutingComponent from "../../dashboard/navigation/dashboard.component";
import EbusinessRoutingComponent from "../../e-business/navigation/e-business.component";
import LogoutComponent from "../pages/logout.component";
import LogoutRedirectComponent from "../../dashboard/pages/logoutRedirect/logoutRedirect.component";

export const MainNavigationRouting = (): Array<RouteInterface> => {
    return [
        { path: AppRoutes.Dashboard.LogoutRedirect, component: LogoutRedirectComponent, exact: true },
        { path: AppRoutes.Dashboard.Logout, component: LogoutComponent, exact: true },
        { path: AppRoutes.EBusiness.ApplyRoles, component: EbusinessRoutingComponent, exact: true },
        { path: AppRoutes.Dashboard.Index, component: DashboardRoutingComponent, guard: RouteProtectionLevel.AUTH_ONLY, exact: false },
        { path: AppRoutes.Landing.Index, component: LandingNavigationRoutingComponent, guard: RouteProtectionLevel.GUEST_ONLY, exact: false }
    ]
}