import { Dialog, TextField, Button } from '@material-ui/core';
import React, { useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import './email-subscription.styles.scss';
import { i18n } from '../../../../common';

const verificationCodeInputStyle = { width: '63%', marginRight: 10 };

const verificationCodeValidator = z.object({
    verificationCode: z.string().min(1, { message: 'Verification code cannot be empty' }),
});

type VerificationCodeType = z.infer<typeof verificationCodeValidator>;

type SubmitVerificationCodeProps = {
    onEmailReset: () => void;
    email: string;
    onVerificationCodeSubmit: (data: VerificationCodeType) => void;
    onVerificationCodeResend: () => void;
    onPopUpClose: () => void;
}

export const SubmitVerificationCode = (
    {
        email,
        onEmailReset,
        onVerificationCodeSubmit,
        onVerificationCodeResend,
        onPopUpClose
    }: SubmitVerificationCodeProps) => {
    const [isOpen, setIsOpen] = useState(true);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ resolver: zodResolver(verificationCodeValidator) });

    const handleClose = () => {
        setIsOpen(false);
        onPopUpClose();
    }

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            disableAutoFocus
        >
            <div className="dialog__top-container">
                <div className="dialog__top-container-title">
                    {i18n.t('email.verifyEmailTitlePart1')} {email} {i18n.t('email.verifyEmailTitlePart2')}
                </div>
                <div className="dialog__top-container-icon" onClick={handleClose}>
                    <CloseIcon />
                </div>
            </div>
            <div className="dialog__form-container">
                <label className="dialog__form-container-label">{i18n.t('email.verificationCodeLabel')}</label>
                <form
                    onSubmit={handleSubmit(data => onVerificationCodeSubmit(data as VerificationCodeType))}
                    className="dialog__form-container-inner"
                >
                    <TextField
                        {...register('verificationCode')}
                        variant="filled"
                        autoFocus
                        type="text"
                        hiddenLabel
                        onBlur={(e) => e.target.focus()}
                        InputProps={{ disableUnderline: true }}
                        style={verificationCodeInputStyle}
                    />
                    <Button className="dialog__form-container-verify-email-button" type="submit">
                        {i18n.t('email.verifyEmail')}
                    </Button>
                    {errors.verificationCode?.message &&
                        <p className="dialog__form-container-error">{errors.verificationCode?.message}</p>
                    }
                </form>
                <div className="dialog__bottom-container">
                    <div className="dialog__bottom-container-description">
                        {i18n.t('email.verificationCodeDescription')}
                    </div>
                    <Button
                        className="dialog__bottom-container-new-code-button"
                        onClick={onVerificationCodeResend}
                    >
                        {i18n.t('email.sendNewVerificationCode')}
                    </Button>
                    <div className="dialog__bottom-container-text">{i18n.t('email.or')}</div>
                    <Button
                        className="dialog__bottom-container-new-email-button"
                        onClick={onEmailReset}
                    >
                        {i18n.t('email.enterNewEmail')}
                    </Button>
                </div>
            </div>
        </Dialog>
    );
}