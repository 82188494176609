import React, { Component } from "react";
import './scrollToNav.styles.scss';
import { Link } from 'react-scroll';

interface Props {
    className: string;
    navLinks: Array<ScrollToNavLinkInterface>;
    offset?: boolean;
    smallOffset?: boolean;
}

export interface ScrollToNavLinkInterface {
    to: string;
    title: string;
}


export class ScrollToNav extends Component<Props> {
    render() {
        let offset = this.props.offset ? -700 : 0;
        offset = this.props.smallOffset ? -400 : offset;
        return (
            <aside className={this.props.className}>
                <nav className="scrollToNav">
                    {this.props.navLinks.map((navLink: ScrollToNavLinkInterface, index: number) =>
                        <Link offset={offset}  key={index} smooth duration={500} spy={true} activeClass="active" to={navLink.to} className="scrollToNav__item">{navLink.title}</Link>)}
                </nav>
            </aside>
        )
    }
}