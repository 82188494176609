import React, { Component } from "react";
import { connect } from "react-redux";
import { AuthStateInterface } from "../../redux/auth";
import { Route, Redirect } from "react-router";
import { StoreStateInterface } from "../../redux";
import { AppRoutes } from "../../constants";



interface Props {
  auth: AuthStateInterface;
  component: any;
  path: string;
  exact: boolean;
}

interface State {
}

class GuestRouteComponent extends Component<Props, State> {


  render() {
    if (!this.props.auth.isAuthentificated) {
      return <Route exact={this.props.exact} path={this.props.path} component={this.props.component} />
    } else {
        return <Redirect to={AppRoutes.Dashboard.Index + window.location.search} />
    }
  }
}

const mapStateToProps = (state: StoreStateInterface) => {
  return {
    auth: state.auth
  };
};

const actions = {
};

export default connect(mapStateToProps, actions)(GuestRouteComponent);
