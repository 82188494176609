import { Dialog, TextField, Button } from '@material-ui/core';
import React, { useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import './email-subscription.styles.scss';
import { i18n } from '../../../../common';

const textFieldStyle = { width: '78%', marginRight: 10 };

const emailValidator = z.object({
    email: z.string().email({ message: 'Please enter a valid email' }),
});

type EmailType = z.infer<typeof emailValidator>;

type SubmitEmailProps = {
    onEmailSubmit: (data: EmailType) => void;
    onPopUpClose: () => void;
}

export const SubmitEmail = ({ onEmailSubmit, onPopUpClose }: SubmitEmailProps) => {
    const [isOpen, setIsOpen] = useState(true);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ resolver: zodResolver(emailValidator) });

    const handleClose = () => {
        setIsOpen(false);
        onPopUpClose();
    }

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            disableAutoFocus
        >
            <div className="dialog__top-container">
                <div className="dialog__top-container-title">
                    {i18n.t('email.enterEmailTitle')}
                </div>
                <div className="dialog__top-container-icon" onClick={handleClose}>
                    <CloseIcon />
                </div>
            </div>
            <div className="dialog__form-container">
                <label className="dialog__form-container-label">{i18n.t('email.emailLabel')}</label>
                <form onSubmit={handleSubmit(data => onEmailSubmit(data as EmailType))} style={{ display: 'flex' }}>
                    <TextField
                        {...register('email')}
                        variant="filled"
                        autoFocus
                        type="text"
                        hiddenLabel
                        onBlur={(e) => e.target.focus()}
                        InputProps={{ disableUnderline: true }}
                        style={textFieldStyle}
                    />
                    <Button className="dialog__form-container-submit-button" type="submit">{i18n.t('email.save')}</Button>
                    {errors.email?.message && <p className="dialog__form-container-error">{errors.email?.message}</p>}
                </form>
            </div>
        </Dialog>
    )
}