import React from "react";
import { StoreStateInterface, NavigationService, AppRoutes, AuthStateInterface, LinksConstant, actionBlockUI, ModalsService, actionUnblockUI } from "../../../../common";
import { connect } from "react-redux";
import './landing.styles.scss';
import LoginImage from '../../../../assets/images/login.jpg';
import Logo from '../../../../assets/images/logo.svg';
import LogoEn from '../../../../assets/images/logo-en.svg';
import LogoDark from '../../../../assets/images/logo-hr-dark.svg';
import LogoEnDark from '../../../../assets/images/logo-en-dark.svg';
import MpsImage from '../../../../assets/images/mp-logo.svg';
import MpsEnImage from '../../../../assets/images/mp-logo-en.svg';
import MpsImageDark from '../../../../assets/images/mp-logo-dark.svg';
import MpsEnImageDark from '../../../../assets/images/mp-logo-en-dark.svg';
import { Button } from "../../../../common/components";
import { withKeycloak } from '@react-keycloak/web'
import { Dialog, IconButton } from "@material-ui/core";
import DialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import { KeycloakInstance } from "keycloak-js";
import keycloak from "../../../../keycloak";
import EGradaniLogo from '../../../../assets/images/e_gradani_logo.png';
import EGradaniLogoEn from '../../../../assets/images/e_gradani_logo-en.png';
import EGradaniLogoDark from '../../../../assets/images/e_gradani_logo-dark.png';
import EGradaniLogoEnDark from '../../../../assets/images/e_gradani_logo-en-dark.png';
import { appendLandingEcitizensHeader } from "../../navigation/landing.routing.component";
import i18n, { setLang } from "../../../../common/services/translate.service";

const lngs = {
    'en': { nativeName: 'EN' },
    'hr': { nativeName: 'HR' }
} as LngsType;

type LngsType = {
    [key: string]: { [key: string]: string }
}

type PropsType = {
    actionBlockUI: () => void;
    auth: AuthStateInterface;
    keycloakInitialized: boolean;
    keycloak: KeycloakInstance;
};

interface State {
    aboutSystem: boolean;
    contact: boolean;
    isDarkMode: boolean;
}

class LandingComponent extends React.Component<PropsType, State> {

    private interval: any;

    constructor(props: PropsType) {
        super(props);
        this.state = {
            aboutSystem: false,
            contact: false,
            isDarkMode: false
        }
    }

    componentDidMount() {
        this.interval = setInterval(() => {
            //@ts-ignore
            if (this.getCookie("cookieImpaired") === "da") {
                this.setState({ isDarkMode: true });
            }
            else {
                this.setState({ isDarkMode: false });
            }
        }
            , 1000)
        actionUnblockUI();
    }

    private aboutSystemModal(): React.ReactNode {
        return (
            <Dialog maxWidth="lg" onClose={() => this.setState({ aboutSystem: false })} aria-labelledby="customized-dialog-title" open={this.state.aboutSystem}>
                <DialogTitle className="landing__modal-title" id="customized-dialog-title">
                    {i18n.t("frontPage.modal.title")}
                    <IconButton aria-label="close" onClick={() => this.setState({ aboutSystem: false })}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                {ModalsService.aboutSystemModalContent()}
            </Dialog>
        )
    }

    private contactModal(): React.ReactNode {
        return (
            <Dialog onClose={() => this.setState({ contact: false })} aria-labelledby="customized-dialog-title" open={this.state.contact}>
                <DialogTitle className="landing__modal-title" id="customized-dialog-title">
                    Ministarstvo poljoprivrede, šumarstva i ribarstva
                    <IconButton aria-label="close" onClick={() => this.setState({ contact: false })}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <MuiDialogContent>
                    {ModalsService.contactModalContent()}
                </MuiDialogContent>
            </Dialog>
        )
    }

    private getCookie(cname: string): string {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    getLogoSrc = () => {
        const lang = window.localStorage.getItem("lang") !== null ? window.localStorage.getItem("lang")! : "hr";
        const isDarkMode = this.state.isDarkMode;

        if (lang === "hr" && isDarkMode) {
            return LogoDark;
        } else if (lang === "hr" && !isDarkMode) {
            return Logo;
        } else if (lang !== "hr" && isDarkMode) {
            return LogoEnDark;
        } else {
            return LogoEn;
        }

    };


    GetMpsLogoSrc = () => {
        const lang = window.localStorage.getItem("lang") !== null ? window.localStorage.getItem("lang")! : "hr";
        const isDarkMode = this.state.isDarkMode;

        if (lang === "hr" && isDarkMode) {
            return MpsImageDark;
        } else if (lang === "hr" && !isDarkMode) {
            return MpsImage;
        } else if (lang !== "hr" && isDarkMode) {
            return MpsEnImageDark;
        } else {
            return MpsEnImage;
        }
    };

    GetNIASLogoSrc = () => {
        const lang = window.localStorage.getItem("lang") !== null ? window.localStorage.getItem("lang")! : "hr";
        const isDarkMode = this.state.isDarkMode;

        if (lang === "hr" && isDarkMode) {
            return EGradaniLogoDark;
        } else if (lang === "hr" && !isDarkMode) {
            return EGradaniLogo;
        } else if (lang !== "hr" && isDarkMode) {
            return EGradaniLogoEnDark;
        } else {
            return EGradaniLogoEn;
        }
    }; 

    render() {
        return (
            <article className="landing">
                <section className="landing__left">
                    <div className="landing__left-img" style={{ background: `url(${LoginImage})` }}>
                    </div>
                </section>
                <section className="landing__right">
                    <div className="landing__header">
                        {/* eslint-disable-next-line*/}
                        <img src={this.getLogoSrc()} className="landing__header-logo" />
                        <div className="landing__header-links">
                            <Button type="link3" onClick={() => this.setState({ aboutSystem: true })}>{i18n.t("frontPage.buttons.aboutTheSystem")}</Button>
                            <Button type="link3" onClick={() => this.setState({ contact: true })}>{i18n.t("frontPage.buttons.contact")}</Button>
                        </div>
                        <div className="appHeader__translation">
                            {Object.keys(lngs).map((lng, index) => {
                                const isActive = (window.localStorage.getItem("lang") !== null ? window.localStorage.getItem("lang") : "hr") === lng;
                                return (
                                    <button
                                        key={lng}
                                        onClick={() => {
                                            setLang(lng);
                                            /*appendLandingEcitizensHeader(lng);*/
                                            window.location.reload();
                                        }}
                                        className={`appHeader__translation-button ${isActive ? 'active' : ''}`}
                                        aria-label={lng === 'hr' ? "Promjeni jezik na hrvatski" : "Switch language to english"}
                                    >
                                        {lngs[lng].nativeName}
                                    </button>
                                );
                            })}
                        </div>
                    </div>
                    <div>
                        <div className="landing__title">{i18n.t("frontPage.title")}</div>
                        <div className="landing__subtitle">{i18n.t("frontPage.subtitle")}</div>
                        <div className="landing__buttons">
                            <Button type="contained" onClick={() => {
                                // this.props.actionBlockUI();
                                this.props.keycloak.login({
                                    idpHint: 'saml-nias'
                                });
                            }}>
                                {i18n.t("frontPage.buttons.login")}
                            </Button>
                            <Button type="outlined" onClick={() => { NavigationService.navigate(AppRoutes.Landing.HowToApply) }}>
                                {i18n.t("frontPage.buttons.howToLogin")}
                            </Button>
                        </div>
                    </div>
                    <div className="landing__logos">
                        <a href={LinksConstant.ministarstvo} target="_blank" rel="noopener noreferrer">
                            <img src={this.GetMpsLogoSrc()} alt="MPS logo" style={{ width: 200 }}></img>
                        </a>
                        <a href={LinksConstant.egradani} target="_blank" rel="noopener noreferrer">
                            <img src={this.GetNIASLogoSrc()} alt="E-Gradani logo"></img>
                        </a>
                    </div>
                </section>
                {this.aboutSystemModal()}
                {this.contactModal()}
            </article>
        );
    }
}

const mapStateToProps = (state: StoreStateInterface) => {
    return {
        auth: state.auth
    }
}

const actions = {
    actionBlockUI,
    actionUnblockUI
};

const redux = connect(
    mapStateToProps,
    actions
)(LandingComponent);

export default withKeycloak(redux)