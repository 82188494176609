import { Dialog, DialogContentText, Grid } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { withKeycloak } from '@react-keycloak/web';
import parse from 'html-react-parser';
import { KeycloakInstance } from "keycloak-js";
import React from "react";
import ReactGA from 'react-ga4';
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import { RouteComponentProps } from "react-router-dom";
import { AuthStateInterface, Button, DBConstants, Loading, StoreStateInterface, SubsystemInfoInterface, SubystemsStateInterface, UserInterface, actionGetSubject, actionGetSubsystems } from "../../../../common";
import i18n from "../../../../common/services/translate.service";
import './applications.styles.scss';
import { EmailSubscription } from "./email-subscription.component";

type PropsType = RouteComponentProps & {
    actionGetSubsystems: (lang: string) => void;
    actionGetSubject: (user: UserInterface) => void;
    subsystems: SubystemsStateInterface;
    auth: AuthStateInterface;
    keycloakInitialized: boolean;
    keycloak: KeycloakInstance;
};

export enum AppViewEnum {
    card,
    list
}

interface State {
    myApplicationsDisplay: AppViewEnum;
    allApplicationsDisplay: AppViewEnum;
    isAboutOpen: boolean;
    aboutSystemText: string;
    registrationInfoText: string;
    contactInfoText: string;
    isDarkMode: boolean;
}

class ApplicationsComponent extends React.Component<PropsType, State> {
    unlisten: any;
    private interval: any;

    constructor(props: PropsType) {
        super(props);
        let localStorageValue = localStorage.getItem("myApplicationsDisplay");
        if (localStorageValue === null) localStorageValue = "0";
        let myApplicationsDisplay = parseInt(localStorageValue);
        if (Number.isNaN(myApplicationsDisplay) || myApplicationsDisplay > 1) {
            myApplicationsDisplay = 0
        }

        let localStorageValue2 = localStorage.getItem("allApplicationsDisplay");
        if (localStorageValue2 === null) localStorageValue2 = "0";
        let myApplicationsDisplay2 = parseInt(localStorageValue2);
        if (Number.isNaN(myApplicationsDisplay2) || myApplicationsDisplay2 > 1) {
            myApplicationsDisplay2 = 0
        }

        this.state = {
            myApplicationsDisplay: myApplicationsDisplay,
            allApplicationsDisplay: myApplicationsDisplay2,
            isAboutOpen: false,
            aboutSystemText: '',
            registrationInfoText: '',
            contactInfoText: '',
            isDarkMode: false
        }
    }

    private getCookie(cname: string): string {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    componentDidMount() {
        this.props.actionGetSubsystems(window.localStorage.getItem("lang") !== null ? window.localStorage.getItem("lang")! : "hr");
        this.interval = setInterval(() => {
            //@ts-ignore
            if (this.getCookie("cookieImpaired") === "da") {
                this.setState({ isDarkMode: true });
            }
            else {
                this.setState({ isDarkMode: false });
            }
        }
            , 1000)

        
        this.props.actionGetSubject(this.props.auth.user!);
    }

    stringToHTML(htmlString: string) {
        return parse(htmlString);
    }

    private aboutSystemModal(): React.ReactNode {
        return (
            <Dialog className="applications__dialog" maxWidth="lg" onClose={() => this.setState({ isAboutOpen: false })} aria-labelledby="customized-dialog-title" open={this.state.isAboutOpen}>
                <div className="dialog__top-container-icon-sticky" onClick={() => this.setState({ isAboutOpen: false })}>
                    <CloseIcon />
                </div>
                <DialogContentText>
                    <h2>{i18n.t('applicationsPage.modal.aboutTheSubsystem')}</h2>
                    {this.stringToHTML(this.state.aboutSystemText)}
                    <h2>{i18n.t('applicationsPage.modal.howToRegister')}</h2>
                    {this.stringToHTML(this.state.registrationInfoText)}
                    <h2>{i18n.t('applicationsPage.modal.contact')}</h2>
                    {this.stringToHTML(this.state.contactInfoText)}
                </DialogContentText>
            </Dialog>
        )
    }

    private renderCardsAvailable(): React.ReactNode {

        return (
            <Grid container spacing={2} className="applications__list">
                {this.props.subsystems.data?.filter((data: SubsystemInfoInterface) => data.aktivan).filter((data: SubsystemInfoInterface) => {
                    let find = this.props.auth.user?.pravaPristupa?.find((pravo: any) => pravo.podsustavId === data.id && pravo.aktivan === true);
                    return find !== undefined && !DBConstants.PlaviDizelId.includes(data.id);
                }
                ).map((subsystem: SubsystemInfoInterface, index: number) => {
                    return (
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={index}>
                            <div className="applications__card" style={{ position: 'relative' }}>
                                <div className="applications__card-top-container">
                                    <div className="applications__card-header">
                                        <img src={`data:image/jpeg;base64,${this.state.isDarkMode ? subsystem.slikaAlt : subsystem.slika}`} alt={subsystem.naziv}></img>
                                    </div>
                                    <div className="applications__card-title">
                                        {subsystem.naziv}
                                    </div>
                                </div>
                                <div className="applications__card-buttons-bg-color">
                                    <Button type="link2" onClick={(e: any) => handleLinkClick(e, subsystem, this.props.auth.token)}
                                        ariaLabel={`${subsystem.naziv} ${i18n.t('applicationsPage.login')}`
                                    }>
                                        {i18n.t('applicationsPage.login')}
                                    </Button>
                                    <Button type="link2" onClick={() => {
                                        this.setState({ isAboutOpen: true, aboutSystemText: subsystem.opis, registrationInfoText: subsystem.registracijaInfo, contactInfoText: subsystem.kontaktInfo })
                                    }}
                                        ariaLabel={`${subsystem.naziv} ${i18n.t('applicationsPage.aboutTheSystem')}`}>
                                        {i18n.t('applicationsPage.aboutTheSystem')}
                                    </Button>
                                </div>
                            </div>
                        </Grid>
                    )
                }
                )}
            </Grid>
        )
    }

    private renderCardsRest(): React.ReactNode {
        return (
            <Grid container spacing={2} className="applications__list">
                {this.props.subsystems.data?.filter((data: SubsystemInfoInterface) => data.aktivan).filter((data: SubsystemInfoInterface) => {
                    let find = this.props.auth.user?.pravaPristupa?.find((pravo: any) => pravo.podsustavId === data.id && pravo.aktivan === true);
                    return find === undefined && !DBConstants.PlaviDizelId.includes(data.id);
                }).map((subsystem: SubsystemInfoInterface, index: number) => {
                    return (
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={index}>
                            <div className="applications__card">
                                <div className="applications__card-top-container">
                                    <div className="applications__card-header">
                                        <img src={`data:image/jpeg;base64,${this.state.isDarkMode ? subsystem.slikaAlt : subsystem.slika}`} alt={subsystem.naziv}></img>
                                    </div>
                                    <div className="applications__card-title">
                                        {subsystem.naziv}
                                    </div>
                                </div>
                                <div className="applications__card-buttons">
                                    {subsystem.registracijaUrl ? (
                                        <Button type="link"
                                            ariaLabel={`${subsystem.naziv} ${i18n.t('applicationsPage.register')} link`}
                                            onClick={() => {
                                            window.location.href = subsystem.naziv !== 'Agronet' ?
                                                (!subsystem.registracijaUrl.includes('?') ?
                                                    subsystem.registracijaUrl + `?token=${this.props.auth.token}` + (!window.location.search ? `` : `&returnUrl=${encodeURIComponent(window.location.search.substring(1))}`) :
                                                    subsystem.registracijaUrl + `&token=${this.props.auth.token}` + (!window.location.search ? `` : `&returnUrl=${encodeURIComponent(window.location.search.substring(1))}`))
                                                : `${subsystem.registracijaUrl}rpslogin.aspx?sliu=${process.env.REACT_APP_ROOT}/aplikacije`;
                                        }}>
                                            {i18n.t('applicationsPage.register')}
                                        </Button>) : <div></div>
                                    }
                                    <Button type="link"
                                        ariaLabel={`${subsystem.naziv} ${i18n.t('applicationsPage.aboutTheSystem')}`}
                                        onClick={() => {
                                        this.setState({ isAboutOpen: true, aboutSystemText: subsystem.opis, registrationInfoText: subsystem.registracijaInfo, contactInfoText: subsystem.kontaktInfo });
                                    }}>
                                        {i18n.t('applicationsPage.aboutTheSystem')}
                                    </Button>
                                </div>
                            </div>
                        </Grid>
                    )
                }
                )}
            </Grid>
        )
    }

    getParameterByName(name: string, url = window.location.href) {
        /* eslint-disable-next-line*/
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    render() {
        return (
            <article>
                <EmailSubscription />
                {this.aboutSystemModal()}
                {(this.props.subsystems.data !== undefined && this.props.subsystems.data?.filter((data: SubsystemInfoInterface) => data.aktivan).filter((data: SubsystemInfoInterface) => {
                    let find = this.props.auth.user?.pravaPristupa?.find((pravo: any) => pravo.podsustavId === data.id && pravo.aktivan === true);
                    return find !== undefined && !DBConstants.PlaviDizelId.includes(data.id);
                }
                ).length > 0) &&
                    < section className="applications">
                        <div className="applications__header">
                            <div className="title title--green">{i18n.t('applicationsPage.registeredInformationSystems')}</div>
                        </div>
                        <Loading isLoading={this.props.subsystems.isLoading && this.props.subsystems.data === undefined} error={this.props.subsystems.error}>
                            {this.state.myApplicationsDisplay === AppViewEnum.card && this.renderCardsAvailable()}
                        </Loading>
                    </section>
                }
                {(this.getParameterByName("categoryId") === "1" || this.getParameterByName("categoryId") === null || this.getParameterByName("categoryId") === "2") &&
                    <section className="applications--all-alternative">
                        <div className="applications">
                            <div className="title title--green applications--all-title">{i18n.t('applicationsPage.otherInformationSystems')}</div>
                            <Loading isLoading={this.props.subsystems.isLoading && this.props.subsystems.data === undefined} error={this.props.subsystems.error}>
                                <Grid container spacing={2} className="applications__list">
                                    {this.props.subsystems.data?.filter((data: SubsystemInfoInterface) => data.aktivan).filter((data: SubsystemInfoInterface) => {
                                        return DBConstants.PlaviDizelId.includes(data.id);
                                    }).map((subsystem: SubsystemInfoInterface, index: number) => {
                                        return (
                                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={index}>
                                                <div className="applications__card">
                                                    <div className="applications__card-top-container">
                                                        <div className="applications__card-header">
                                                            <img src={`data:image/jpeg;base64,${this.state.isDarkMode ? subsystem.slikaAlt : subsystem.slika}`} alt={subsystem.naziv}></img>
                                                        </div>
                                                        <div className="applications__card-title">
                                                            {subsystem.naziv}
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="applications__card-buttons">
                                                        <Button type="link"
                                                            ariaLabel={`${subsystem.naziv} ${i18n.t('applicationsPage.login')}`}
                                                            onClick={(e: any) => handleLinkClick(e, subsystem, this.props.auth.token)}>
                                                            {i18n.t('applicationsPage.login')}
                                                        </Button>
                                                        <Button type="link"
                                                            ariaLabel={`${subsystem.naziv} ${i18n.t('applicationsPage.aboutTheSystem')}`}
                                                            onClick={() => {this.setState({ isAboutOpen: true, aboutSystemText: subsystem.opis, registrationInfoText: subsystem.registracijaInfo, contactInfoText: subsystem.kontaktInfo });
                                                        }}>
                                                            {i18n.t('applicationsPage.aboutTheSystem')}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Grid>
                                        )
                                    }
                                    )}
                                </Grid>
                            </Loading>
                        </div>
                    </section>
                }
                <section className="applications--all">
                    <div className="applications">
                        <Loading isLoading={this.props.subsystems.isLoading && this.props.subsystems.data === undefined} error={this.props.subsystems.error}>
                            <div className="applications__header">
                                {(this.props.subsystems.data && this.props.subsystems.data.length > 0) &&
                                    <>
                                        {this.props.subsystems.data?.filter((data: SubsystemInfoInterface) => data.aktivan).filter((data: SubsystemInfoInterface) => {
                                            let find = this.props.auth.user?.pravaPristupa?.find((pravo: any) => pravo.podsustavId === data.id && pravo.aktivan === true);
                                            return find === undefined && !DBConstants.PlaviDizelId.includes(data.id);
                                        }).length === 0 &&
                                            <div>Trenutno ste registrirani u sve informacijske sustave</div>
                                        }
                                        {this.props.subsystems.data?.filter((data: SubsystemInfoInterface) => data.aktivan).filter((data: SubsystemInfoInterface) => {
                                            let find = this.props.auth.user?.pravaPristupa?.find((pravo: any) => pravo.podsustavId === data.id && pravo.aktivan === true);
                                            return find === undefined && !DBConstants.PlaviDizelId.includes(data.id);
                                        }).length > 0 &&
                                            <>
                                                {(this.props.subsystems.data && this.props.subsystems.data.length > 0) &&
                                                    <>
                                                        <div className="title title--green applications--all-title">{i18n.t('applicationsPage.notRegisteredInformationSystems')}</div>
                                                    </>
                                                }
                                                <div className="paragraph--bold"></div>
                                            </>
                                        }
                                    </>
                                }
                                {(this.props.subsystems.data && this.props.subsystems.data.length === 0) &&
                                    <>
                                        {this.getParameterByName("categoryId") === "2" &&
                                            <div className="title title--green applications__in-develop">
                                                <div>Informacijski sustavi Ribarstva bit će vidljivi nakon integracije sustava s ePoljoprivredom</div>
                                            </div>
                                        }
                                        {this.getParameterByName("categoryId") === "3" &&
                                            <div className="title title--green applications__in-develop">
                                                <div>Informacijski sustavi Šumarstva bit će vidljivi nakon integracije sustava s ePoljoprivredom</div>
                                            </div>
                                        }
                                        {this.getParameterByName("categoryId") === "4" &&
                                            <div className="title title--green applications__in-develop">
                                                <div>Informacijski sustavi Veterine bit će vidljivi nakon integracije sustava s ePoljoprivredom</div>
                                            </div>
                                        }
                                    </>
                                }
                            </div>
                            {this.state.allApplicationsDisplay === AppViewEnum.card && this.renderCardsRest()}
                        </Loading>
                    </div>
                </section>
            </article>
        );
    }
}

const mapStateToProps = (state: StoreStateInterface) => {
    return {
        subsystems: state.subsystems,
        auth: state.auth
    }
}

const actions = {
    actionGetSubsystems,
    actionGetSubject
};

const handleLinkClick = (event: any, subsystem: SubsystemInfoInterface, token: string | undefined) => {
    ReactGA.event({
        category: subsystem.naziv,
        action: 'PodsustavKlik'
    });
    let subsystemUrl: string = subsystem.url;
    
    if (subsystem.naziv === 'Agronet') {
        subsystemUrl =
            subsystemUrl +
            (!window.location.search ? `` : `&returnUrl=${encodeURIComponent('/change_entity?' + window.location.search)}`);
    }
    //dodano za GISR jer ne žele da se šalju ePoslovanje parametri
    else if (subsystem.id === 14) {
        subsystemUrl =
            subsystemUrl +
            (!subsystem.url.includes('?') ? `?` : `&`) +
            `token=${token}`;
    }
    else {
        subsystemUrl =
            subsystemUrl +
            (!subsystem.url.includes('?') ? `?` : `&`) +
            `token=${token}` +
            (!window.location.search ? `` : `&returnUrl=${encodeURIComponent(window.location.search.substring(1))}`);
    }

    window.location.href = subsystemUrl;

};
const redux = withRouter(connect(
    mapStateToProps,
    actions
)(ApplicationsComponent));

export default withKeycloak(redux)