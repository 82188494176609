import { AnyAction } from "redux";
import { BlockUIConstant } from "./blockUI.constant";



const initialState: boolean  = false;

export const block = (): boolean => true;

export const unblock = (): boolean => false;

export function blockUIReducer(state: boolean = initialState, action: AnyAction): boolean {
    switch(action.type) {
        case BlockUIConstant.START: return block();
        case BlockUIConstant.END: return unblock();
    }
    return state;
}
