import { AnyAction } from "redux";
import { EBusinessConstant } from "./e-business.constant";

export interface AuthFormData {
    id?: string;
    grantUrl?: string;
    cancelUrl?: string;
    authorizer?: {
        legalEntity: {
            name: string;
            subjectIdentificationNumber: string;
        },
        physicalEntity: {
            firstName: string;
            lastName: string;
            personalIdentificationNumber: string;
        },
    },
    assignee?: {
        legalEntity: {
            name: string;
            subjectIdentificationNumber: string;
        },
        physicalEntity: {
            firstName: string;
            lastName: string;
            personalIdentificationNumber: string;
        },
    },
    powerOfAttorneyFor?: {
        legalEntity: {
            name: string;
            subjectIdentificationNumber: string;
        },
    },
    isLoading: boolean;
    permissions: Array<{
        podsustav: string;
        dozvole: Array<{
            title: string;
            description: string;
            key: string;
            value?: boolean;
        }>;
    }>;
    roles: Array<{
        key: string;
        value: string;
        description: string;
        valueDescription: string;
        roleType: string;

    }>;
    serviceResponse: string;
    submitUrl: string;
}

const initialState: AuthFormData = {
    isLoading: false,
    id: "_ff8cf8dabfb04faa9c61f1162eb96330",
    grantUrl: `${process.env.REACT_APP_EOVLASTENJA}/webapi/api/AuthorizationsApi/ReceiveAuthorizeResponse/3`,
    cancelUrl: `${process.env.REACT_APP_EOVLASTENJA}/webapi/api/HomeApi/CancelAuthorizeResponse`,
    authorizer: {
        legalEntity: {
            name: "ADRIATIQUE GROUP D.O.O. ZA POSLOVANJE NEKRETNINAMA",
            subjectIdentificationNumber: "39986540678",
        },
        physicalEntity: {
            firstName: "PERO",
            lastName: "PERIĆ",
            personalIdentificationNumber: "12312312316"
        },
    },
    assignee: {
        legalEntity: {
            name: "ADRIATIQUE GROUP D.O.O. ZA POSLOVANJE NEKRETNINAMA",
            subjectIdentificationNumber: "39986540678"
        },
        physicalEntity: {
            firstName: "PERO",
            lastName: "PERIC",
            personalIdentificationNumber: "12312312316",
        },
    },
    powerOfAttorneyFor: {
        legalEntity: {
            name: "ADRIATIQUE GROUP D.O.O. ZA POSLOVANJE NEKRETNINAMA",
            subjectIdentificationNumber: "",
        },
    },
    roles: [],
    serviceResponse: '',
    submitUrl: '',
    permissions: [],
}

export const getAuthFormData = (state: AuthFormData, payload: AuthFormData): AuthFormData => {
    return {
        ...state,
        ...payload,
    }
}

export const getPermissions = (state: AuthFormData, payload: any): AuthFormData => {
    return {
        ...state,
        permissions: [...payload],
    }
}

export const grantAccess = (state: AuthFormData, payload: any): AuthFormData => {
    return {
        ...state,
        ...payload
    }
}

export function eBusinessReducer(state: AuthFormData = initialState, action: AnyAction): AuthFormData {
    switch (action.type) {
        case EBusinessConstant.AUTH_FORM: return getAuthFormData(state, action.payload);
        case EBusinessConstant.PERMISSIONS: return getPermissions(state, action.payload);
        case EBusinessConstant.GRANT_ACCESS: return grantAccess(state, action.payload);
    }
    return state;
}