import React from "react";
import { actionUpdateToken, NavigationService, ErrorStateInterface, Alert, actionRefreshError, Footer, actionLogout, AuthStateInterface, persistor, AppRoutes, actionChangeOib, KeycloackService } from "../../../common";
import { DashboardNavigationRouting } from "./dashboard.routing";
import AppHeader from "../../../common/components/appHeader/appHeader.component";
import { connect } from "react-redux";
import { withKeycloak } from '@react-keycloak/web';
import { StoreStateInterface } from '../../../common';
import Snackbar from '@material-ui/core/Snackbar';
import { SystemsStateInterface } from "../../../common/redux/systems";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { RouteComponentProps, withRouter } from "react-router-dom";
import { KeycloakInstance } from "keycloak-js";
import keycloak from "../../../keycloak";
import queryString from 'query-string';


type PropsType = RouteComponentProps & {
    error: ErrorStateInterface;
    systems: SystemsStateInterface;
    actionRefreshError: () => void;
    actionLogout: () => void;
    actionUpdateToken: (accessToken: string, refreshToken: string) => void;
    blockUI: boolean;
    auth: AuthStateInterface;
    keycloakInitialized: boolean;
    keycloak: KeycloakInstance;
    actionChangeOib: any;
};

export function appendDashboardEcitizensHeader(lang: string, auth: AuthStateInterface, actionChangeOib?: any) {
    let trakica = document.getElementById("trakicaScript");
    const changeEntityUrl = process.env.REACT_APP_ROOT + '/aplikacije?ForLegalIps={ForLegalIps}%26ForPersonOib={ForPersonOib}%26ForLegalIzvor_reg={ForLegalIzvor_reg}%26ToLegalIps={ToLegalIps}%26ToLegalIzvor_reg={ToLegalIzvor_reg}';
    const result = queryString.parse(window.location.search);
    const { ForLegalIps = undefined, ForLegalIzvor_reg = undefined, ToLegalIps = undefined, ToLegalIzvor_reg = undefined, ForPersonOib = undefined } = result;

    if (Object.keys(result).length !== 0) {
        localStorage.setItem('ENTITY_DATA', JSON.stringify(result));
    }

    let entitySearch = localStorage.getItem("entitySearch");



    let trakicaSrc = '';
    if (ForLegalIps || ForLegalIzvor_reg || ToLegalIps || ToLegalIzvor_reg) {
        trakicaSrc = `${process.env.REACT_APP_NIAS_HEADER_URL}?logout_url=${process.env.REACT_APP_ROOT}${AppRoutes.Dashboard.LogoutRedirect}&messageId=${auth.MessageId}&navtoken=${auth.NavToken}&language=${lang}&show_entities=true&show_persons=true&show_entity_search=${entitySearch === "1"}&change_entity_url=${changeEntityUrl}&ForLegalIps=${ForLegalIps}&ForLegalIzvor_reg=${ForLegalIzvor_reg}&ToLegalIps=${ToLegalIps}&ToLegalIzvor_reg=${ToLegalIzvor_reg}`;
    } else if (ForPersonOib) {
        trakicaSrc = `${process.env.REACT_APP_NIAS_HEADER_URL}?logout_url=${process.env.REACT_APP_ROOT}${AppRoutes.Dashboard.LogoutRedirect}&messageId=${auth.MessageId}&navtoken=${auth.NavToken}&language=${lang}&show_entities=true&show_persons=true&show_entity_search=${entitySearch === "1"}&change_entity_url=${changeEntityUrl}&ForPersonOib=${ForPersonOib}`;
    }
    else {
        trakicaSrc = `${process.env.REACT_APP_NIAS_HEADER_URL}?logout_url=${process.env.REACT_APP_ROOT}${AppRoutes.Dashboard.LogoutRedirect}&messageId=${auth.MessageId}&navtoken=${auth.NavToken}&language=${lang}&show_entities=true&show_persons=true&show_entity_search=${entitySearch === "1"}&change_entity_url=${changeEntityUrl}`
    }

    if (ForPersonOib) {
        actionChangeOib(ForPersonOib);
    } else if (ForLegalIps) {
        actionChangeOib(ForLegalIps);
    }

    let egradjani = document.getElementById("egradjani");

    if (trakica !== null) {
        trakica.remove();
    }

    if (egradjani !== null) {
        egradjani.remove();
    }

    let trakicaCreate = () => {
        let script = document.createElement("script");

        script.src = trakicaSrc;
        script.async = true;
        script.id = "trakicaScript"
        document.body.appendChild(script);
        localStorage.removeItem("entitySearch");
    }

    if (ForLegalIps || ForLegalIzvor_reg || ToLegalIps || ToLegalIzvor_reg) {
        setTimeout(() => trakicaCreate(), 1000)
    } else {
        trakicaCreate()
    }
}

export class DashboardRoutingComponent extends React.Component<PropsType>{
    private interval: any;
    private checkInterval: any;

    componentDidMount() {
        this.interval = setInterval(() => {
            if (localStorage.getItem("logout") === "1") {
                localStorage.removeItem("logout");
                persistor.purge();
                this.props.keycloak.logout();
            }
        }, 2000)

        keycloak.onAuthRefreshSuccess = () => {
            if (!!keycloak.token && !!keycloak.refreshToken)
                this.props.actionUpdateToken(keycloak.token, keycloak.refreshToken)
        }


        this.checkInterval = setInterval(() => {
            /*  if (!!keycloak.tokenParsed && keycloak.tokenParsed.exp != undefined) {
                  if (keycloak.tokenParsed.exp > Math.floor(Date.now() / 1000)) {
                      //@ts-ignore
                      let tokenParsed = keycloak!.tokenParsed! as any;
                      let username = tokenParsed.preferred_username;
                      KeycloackService.inspectSession(username).subscribe(async (data: any) => {
                          if (data === false) {
                              await persistor.purge();
                              setTimeout(() => window.location.href = "/", 1000);
                          }
                      })
                  }
              } */
            if (!!!keycloak.tokenParsed) {
                persistor.purge();
                setTimeout(() => window.location.href = "/", 1000);
            }

            if (process.env.REACT_APP_ENVIRONMENT !== "development") {
                if (!!keycloak.tokenParsed) {
                    KeycloackService.inspectSession(keycloak.tokenParsed.preferred_username).subscribe(async (data: any) => {
                        if (data === false) {
                            await persistor.purge();
                            setTimeout(() => window.location.href = "/", 1000);
                        }
                    })
                }
            }
        }, 4000)

      

        setTimeout(() => { localStorage.removeItem("registerStarted"); }, 4000)

        //localStorage.removeItem("privolaLogin");

        if (this.props.auth.isAuthentificated) {
            appendDashboardEcitizensHeader(window.localStorage.getItem("lang") !== null ? window.localStorage.getItem("lang")! : "hr", this.props.auth, this.props.actionChangeOib);
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval)
        clearInterval(this.checkInterval)
    }

    componentDidUpdate(prevProps: PropsType) {
        if (this.props.location !== prevProps.location) {
            this.onRouteChanged();
        }
    }

    onRouteChanged() {
        /*if (!!this.props.auth.user && !!this.props.auth.user.username) {
            KeycloackService.inspectSession(this.props.auth.user.username).subscribe((data: any) => {
                if (data === false) {
                    persistor.purge();
                }
            })
        } */
    }


    render() {
        if (localStorage.getItem("logout") === "1") {
            return (
                <div>Preusmjeravanje...</div>
            )
        }
        return (
            <>
                <AppHeader systems={this.props.systems} auth={this.props.auth} actionLogout={this.props.actionLogout}></AppHeader>
                <div>
                    {NavigationService.generateRoutes(DashboardNavigationRouting())}
                </div>
                <Footer></Footer>
                <Snackbar open={this.props.error.error && this.props.error.message !== undefined && this.props.error.message.length > 0} autoHideDuration={6000} onClose={() => {
                    this.props.actionRefreshError()
                }}>
                    <Alert onClose={() => { if (this.props.error.action !== undefined) this.props.error.action(); else this.props.actionRefreshError() }} severity="error">
                        {this.props.error.message}
                    </Alert>
                </Snackbar>
                <Backdrop open={this.props.blockUI} style={{ zIndex: 500 }}>
                    <CircularProgress color="primary" />
                </Backdrop>
            </>
        )
    }
}

const mapStateToProps = (state: StoreStateInterface) => {
    return {
        error: state.base.error,
        systems: state.systems,
        blockUI: state.base.blockUI,
        auth: state.auth
    }
}

const actions = {
    actionRefreshError,
    actionLogout,
    actionChangeOib,
    actionUpdateToken
};

const redux = withRouter(connect(
    mapStateToProps,
    actions
)(DashboardRoutingComponent));

export default withKeycloak(redux);





