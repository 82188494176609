import { combineReducers } from "redux";
import { authReducer } from "./auth";
import { BaseReducers } from "./base";
import { systemsReducer } from "./systems";
import { subsystemsReducer } from "./subSystems";
import { eBusinessReducer } from "./e-business/e-business.reducer";


export const AppReducers = combineReducers({
    auth: authReducer,
    base: BaseReducers,
    systems: systemsReducer,
    subsystems: subsystemsReducer,
    eBusiness: eBusinessReducer
});

export type StoreStateInterface = ReturnType<typeof AppReducers>;