import React, { useEffect, useRef, useState } from 'react';
import { Button } from '@material-ui/core';
import queryString from 'query-string';
import $ from 'jquery';

import Logo from '../../../../assets/images/logo.svg';
import { connect } from 'react-redux';
import { actionGetAuthFormData, actionGetPermissions, actionGrantAccess } from '../../../../common/redux/e-business';
import { ErrorConstant, StoreStateInterface, i18n, store } from '../../../../common';
import { AuthFormData } from '../../../../common/redux/e-business/e-business.reducer';

type ApplyRolesProps = {
    actionGetAuthFormData: any;
    actionGetPermissions: any;
    actionGrantAccess: any;
    eBusiness: AuthFormData;
}

/*const ROLES = [
    {
        key: "agronet_pristup_001",
        value: "true",
        description: "Pravo pristupa na Agronet",
        valueDescription: "",
        roleType: "Permission"
    },
    {
        key: "ups,_pristup_001",
        value: "true",
        description: "Pravo pristupa na Upisnik privatnih šumoposjednika",
        valueDescription: "",
        roleType: "Permission"
    },
    {
        key: "ezakup_pristup_001",
        value: "true",
        description: "Pravo pristupa na eZakup",
        valueDescription: "",
        roleType: "Permission"
    },
    {
        key: "uspd_pristup_001",
        value: "false",
        description: "Pravo pristupa na Uvid u stanje plavog dizela",
        valueDescription: "",
        roleType: "Permission"
    },
    {
        key: "uspd_pristup_001",
        value: "false",
        description: "Pravo pristupa na Uvid u stanje plavog dizela",
        valueDescription: "",
        roleType: "Permission"
    },
    {
        key: "wave_pristup_001",
        value: "false",
        description: "Pravo pristupa na Wave-ŠŠ",
        valueDescription: "",
        roleType: "Permission"
    },
    {
        key: "ukl_pristup_001",
        value: "false",
        description: "Pravo pristupa na Upisnik kućnih ljubimaca",
        valueDescription: "",
        roleType: "Permission"
    },
    {
        key: "fis_pristup_001",
        value: "false",
        description: "Pravo pristupa na Fitosanitarni informacijski sustav",
        valueDescription: "",
        roleType: "Permission"
    },
    {
        key: "gsr_pristup_001",
        value: "false",
        description: "Pravo pristupa na Geoinformacijski sustav ribarstva",
        valueDescription: "",
        roleType: "Permission"
    },
    {
        key: "pgr_pristup_001",
        value: "false",
        description: "Pravo pristupa na Portal gospodarskog ribarstva",
        valueDescription: "",
        roleType: "Permission"
    },
    {
        key: "sle_pristup_001",
        value: "false",
        description: "Pravo pristupa na Središnju lovnu evidenciju",
        valueDescription: "",
        roleType: "Permission"
    },
    {
        key: "fis_pristup_001",
        value: "false",
        description: "Pravo pristupa na Fitosanitarni informacijski sustav",
        valueDescription: "",
        roleType: "Permission"
    },
    {
        key: "uuklnp_pristup_001",
        value: "false",
        description: "Pravo pristupa na Upisnik uzgoja kućnih ljubimaca namijenjenih prodaji",
        valueDescription: "",
        roleType: "Permission"
    },
    {
        key: "ups,_pristup_001",
        value: "false",
        description: "Pravo pristupa na Upisnik privatnih šumoposjednika",
        valueDescription: "",
        roleType: "Permission"
    },
    {
        key: "rdp_pristup_001",
        value: "false",
        description: "Pravo pristupa na Registar državnih potpora",
        valueDescription: "",
        roleType: "Permission"
    },
    {
        key: "jrdz_pristup_001",
        value: "false",
        description: "Pravo pristupa na Posjednik (JRDŽ)",
        valueDescription: "",
        roleType: "Permission"
    },
    {
        key: "fis_test_pristup_001",
        value: "false",
        description: "Pravo pristupa na FIS-test",
        valueDescription: "",
        roleType: "Permission"
    }
];*/

/*const PERMISSIONS = [
    {
        podsustav: "Portal gospodarskog ribarstva",
        dozvole: [
            {
                title: "Pravo pristupa",
                description: "Pravo pristupa na Portal gospodarskog ribarstva",
                key: "pgr_pristup_001",
                value: true
            },
            {
                title: "Pravo pristupa 2",
                description: "Pravo pristupa na Portal gospodarskog ribarstva 2",
                key: "pgr_pristup_002",
            }
        ]
    },
    {
        podsustav: "Agronet",
        dozvole: [
            {
                title: "Pravo pristupa",
                description: "Pravo pristupa na Agronet",
                key: "agronet_pristup_001",
            }
        ]
    }
]*/

/*const test = () => {
    let permissionsCopy = [...PERMISSIONS];
    ROLES.forEach((role: any) => {
        for (let permission of permissionsCopy) {
            for (let doz of permission?.dozvole) {
                if (doz?.key === role?.key) {
                    if (role?.value === 'true') {
                        doz.value = true;
                    }
                };
            }
        }
    });
    console.log('permissionsCopy', permissionsCopy);
}*/

type ActiveRoles = Array<{
    podsustav: string;
    dozvole: Array<{
        title: string;
        description: string;
        key: string;
        value?: boolean;
    }>;
}>

const ApplyRolesComponent = (props: ApplyRolesProps) => {
    const [form, setForm] = useState<any>([]);
    const isInitialMount = useRef(true);
    const [activeRoles, setActiveRoles] = useState<ActiveRoles>([]);

    useEffect(() => {
        const { requestId = undefined } = queryString.parse(window.location.search);
        if (requestId) {
            props.actionGetAuthFormData(requestId as string);
        }
        /* eslint-disable-next-line*/
    }, []);

    useEffect(() => {
        if (props.eBusiness?.powerOfAttorneyFor?.legalEntity?.subjectIdentificationNumber !== '') {
            props.actionGetPermissions(props.eBusiness?.powerOfAttorneyFor?.legalEntity?.subjectIdentificationNumber);
        }
        /* eslint-disable-next-line*/
    }, [props.eBusiness?.powerOfAttorneyFor?.legalEntity?.subjectIdentificationNumber]);

    useEffect(() => {
        const { permissions } = props.eBusiness;
        if (permissions && permissions?.length > 0) {
            let permissionsCopy = [...permissions];
            props.eBusiness?.roles?.forEach((role: any) => {
                for (let permission of permissionsCopy) {
                    for (let doz of permission?.dozvole) {
                        if (doz?.key === role?.key) {
                            if (role?.value === 'true') {
                                doz.value = true;
                            }
                        };
                    }
                }
            });
            setActiveRoles(permissionsCopy);
        }
        if (permissions && permissions?.length === 0) {
            setActiveRoles([]);
        }
        /* eslint-disable-next-line*/
    }, [props.eBusiness?.permissions]);

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            const a = [];
            a.push(<form id="target" action={props?.eBusiness?.submitUrl} method="POST">
                <input type="hidden" name="ServiceResponse" value={props?.eBusiness?.serviceResponse} />
            </form>);
            setForm(a);

            setTimeout(() => {
                $('#target').submit();
            }, 1000);
        }
        /* eslint-disable-next-line*/
    }, [props?.eBusiness?.submitUrl]);

    const { authorizer, assignee, powerOfAttorneyFor } = props.eBusiness;

    const handleSubmit = () => {
        if (activeRoles.length === 0) {
            store.dispatch({
                type: ErrorConstant.ERROR, payload:
                {
                    message: i18n.t("error.eovlastenja"),
                    action: () => { }
                }
            }
            )
            return;
        }
        let filteredRoles = [] as ActiveRoles;
        activeRoles.forEach(role => {
            const activeRoles = role.dozvole.filter(d => d.value !== undefined && d.value !== false);
            if (activeRoles?.length > 0) {
                filteredRoles.push({ ...role, dozvole: activeRoles })
            }
        });
        props.actionGrantAccess(props.eBusiness, filteredRoles);
    }

    const handleCheckboxClick = (dozIndex: any, roleIndex: any) => {
        const roles = [...activeRoles];
        const checkedRole = roles[roleIndex]['dozvole'][dozIndex];
        if ('value' in checkedRole) {
            checkedRole.value = !checkedRole.value
        } else {
            checkedRole.value = true;
        }
        setActiveRoles([...roles]);
    }

    return (
        <div style={{ padding: '100px 200px', backgroundColor: '#e5e5e5', height: '100vh', overflow: 'auto' }}>
            <div>{form}</div>
            {/* eslint-disable-next-line*/}
            <img src={Logo} style={{ width: 200, paddingBottom: 24 }} />
            <div style={{ display: 'flex', flexDirection: 'column', paddingBottom: 32 }}>
                <div style={{ borderBottom: '1px solid lightgray', marginBottom: 16, paddingBottom: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span style={{ fontSize: 18, color: '#41927F', fontWeight: 'bold' }}>TKO</span>
                    <span style={{ fontSize: 16 }}>{authorizer?.physicalEntity?.firstName} {authorizer?.physicalEntity?.lastName} (OIB :{authorizer?.physicalEntity?.personalIdentificationNumber})</span>
                </div>
                <div style={{ borderBottom: '1px solid lightgray', marginBottom: 16, paddingBottom: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span style={{ fontSize: 18, color: '#41927F', fontWeight: 'bold' }}>KOME</span>
                    <span style={{ fontSize: 16 }}>{assignee?.physicalEntity?.firstName} {assignee?.physicalEntity?.lastName} (OIB: {assignee?.physicalEntity?.personalIdentificationNumber})</span>
                </div>
                <div style={{ borderBottom: '1px solid lightgray', marginBottom: 16, paddingBottom: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span style={{ fontSize: 18, color: '#41927F', fontWeight: 'bold' }}>ZA ŠTO</span>
                    <span style={{ fontSize: 16 }}>{powerOfAttorneyFor?.legalEntity?.name} {powerOfAttorneyFor?.legalEntity?.subjectIdentificationNumber ? `(OIB: ${powerOfAttorneyFor?.legalEntity?.subjectIdentificationNumber})` : ''}</span>
                </div>
            </div>
            {activeRoles?.map((role: any, roleIndex: any) => {
                return (
                    <div key={roleIndex} style={{ display: 'flex', flexDirection: 'column', paddingBottom: 24 }}>
                        <div style={{ borderBottom: '1px solid lightgray', marginBottom: 8, paddingBottom: 4 }}>
                            <span style={{ fontSize: 18, color: '#41927F', fontWeight: 'bold' }}>{role.podsustav}</span>
                        </div>
                        {role?.dozvole?.map((r: any, index: any) => {
                            return (
                                <div style={{ paddingBottom: 4 }} key={r.key}>
                                    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                        <span style={{ fontSize: 16 }}>{r.title}</span>
                                        <input type="checkbox" checked={r.value ?? false} style={{ transform: 'scale(1.5)' }} onChange={() => handleCheckboxClick(index, roleIndex)} />
                                    </div>
                                    <span style={{ fontSize: 14 }}>{r.description}</span>
                                </div>
                            );
                        })}
                    </div>
                );
            })}
            {activeRoles?.length === 0 && (
                <div style={{ paddingBottom: 32, fontSize: 16, display: 'flex', justifyContent: 'center' }}>Poslovni subjekt nije registriran u niti jedan podsustav</div>
            )}
            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', gap: 16 }}>
                {activeRoles?.length > 0 && (
                    <Button onClick={handleSubmit} style={{ width: '15%' }}>
                        Potvrdi
                    </Button>
                )}
                <Button onClick={() => window.location.href = props?.eBusiness?.cancelUrl as string} style={{ width: '15%', backgroundColor: '#924154 !important' }}>
                    Odustani
                </Button>
            </div>
        </div>
    )
}

const mapStateToProps = (state: StoreStateInterface) => {
    return {
        eBusiness: state.eBusiness
    }
}

const actions = {
    actionGetAuthFormData,
    actionGetPermissions,
    actionGrantAccess,
};

export const ApplyRoles = connect(
    mapStateToProps,
    actions
)(ApplyRolesComponent);