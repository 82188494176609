import { Dispatch } from "redux";
import { BlockUIConstant } from "./blockUI.constant";

export function actionBlockUI() {
  return (dispatch: Dispatch) => {
    dispatch({ type: BlockUIConstant.START });
  };
}

export function actionUnblockUI() {
  return (dispatch: Dispatch) => {
    dispatch({ type: BlockUIConstant.END });
  };
}
