import React from "react";
import { AuthStateInterface, StoreStateInterface, actionLogout, NavigationService, AppRoutes } from "../../../common";
import { connect } from "react-redux";
import keycloak from "../../../keycloak";

interface Props {
    auth: AuthStateInterface;
    actionLogout: () => void;
}

export class LogoutComponent extends React.Component<Props> {
    componentDidMount() {
        setTimeout(() => {
            if (this.props.auth.isAuthentificated) {
                keycloak.logout();
                this.props.actionLogout();
            } else {
                NavigationService.navigate(AppRoutes.Landing.Index)
            }
        }, 1000)
    }

    render() {
        return (
            <div>
                Preusmjeravam...
            </div>
        );
    }
}

const mapStateToProps = (state: StoreStateInterface) => {
    return {
        auth: state.auth
    }
}

const actions = {
    actionLogout
};

export default connect(
    mapStateToProps,
    actions
)(LogoutComponent);