import './profile.styles.scss';
import React, { useEffect, useState } from "react";
import { AuthStateInterface, i18n, SubjectService } from "../../../../common";
import Grid from '@material-ui/core/Grid';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Dialog, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import queryString from "query-string";

const emailValidator = z.object({
    email: z.string().email({ message: 'Please enter a valid email' }),
});

type EmailInputProps = {
    auth: AuthStateInterface;
    onGetSubject: () => void;
    onPopUpEnable: () => void;
}

export const EmailInput = ({ auth, onGetSubject, onPopUpEnable }: EmailInputProps) => {
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors },
    } = useForm({ resolver: zodResolver(emailValidator) });

    useEffect(() => {
        setValue('email', auth.user?.email);
       // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth.user?.email]);

    const onDeleteSubmit = () => {
        const result = queryString.parse(window.location.search);
        const { ForLegalIzvor_reg = undefined, ForLegalIps = undefined } = result;
        let identifier = '';
        if (ForLegalIps !== undefined && ForLegalIps !== '') {
            identifier = ForLegalIps as string;
        }
        else {
            identifier = encodeURIComponent(auth.user?.oib!);
        }
        SubjectService.deleteEmail(identifier, auth.token!, ForLegalIzvor_reg as string).subscribe((data) => {
            setValue('email', '');
            onGetSubject();
            setIsDeleteDialogOpen(false);
        });
    }

    const handleDeleteClick = () => {
        setIsDeleteDialogOpen(true);
    }

    return (
        <>
            {isDeleteDialogOpen && <EmailDeleteDialog onDeleteSubmit={onDeleteSubmit} isOpen={isDeleteDialogOpen} onClose={() => setIsDeleteDialogOpen(false)} />}
            <form onSubmit={handleSubmit(data => console.log('data', data))} style={{ width: '100%' }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            {...register('email')}
                            variant="filled"
                            type="text"
                            hiddenLabel
                            InputProps={{ disableUnderline: true }}
                            style={{ width: '100%' }}
                            placeholder="Email"
                        />
                        {errors.email?.message &&
                            <p style={{ marginTop: 4, fontSize: 12, color: 'red' }}>{errors.email?.message}</p>
                        }
                        {!errors.email?.message && auth.user?.isEmailVerified &&
                            <p style={{ marginTop: 4, fontSize: 12 }}>{i18n.t('email.emailVerified')}</p>
                        }
                        {!errors.email?.message && !auth.user?.isEmailVerified && auth.user?.email &&
                            <p style={{ marginTop: 4, fontSize: 12 }}>{i18n.t('email.emailNotVerified')}</p>
                        }
                        {!errors.email?.message && !auth.user?.isEmailVerified && auth.user?.email &&
                            <Button
                                disabled={!!errors.email?.message}
                                style={{ width: '100%' }}
                                onClick={() => {
                                    const result = queryString.parse(window.location.search);
                                    const { ForLegalIzvor_reg = undefined, ForLegalIps = undefined } = result;
                                    let identifier = '';
                                    if (ForLegalIps !== undefined && ForLegalIps !== '') {
                                        identifier = ForLegalIps as string;
                                    }
                                    else {
                                        identifier = encodeURIComponent(auth.user?.oib!);
                                    }
                                    SubjectService.sendEmailVerification(identifier, getValues('email'), auth.token!, ForLegalIzvor_reg as string).subscribe((data) => {
                                        onGetSubject();
                                        setTimeout(() => {
                                            onPopUpEnable();
                                        }, 1000);
                                    });
                                }}
                            >
                                {i18n.t('email.sendVerificationCode')}
                            </Button>
                        }
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Button
                            disabled={!!errors.email?.message}
                            type="submit"
                            style={{ width: '100%' }}
                            onClick={() => {
                                const result = queryString.parse(window.location.search);
                                const { ForLegalIzvor_reg = undefined, ForLegalIps = undefined } = result;
                                let identifier = '';
                                if (ForLegalIps !== undefined && ForLegalIps !== '') {
                                    identifier = ForLegalIps as string;
                                }
                                else {
                                    identifier = encodeURIComponent(auth.user?.oib!);
                                }
                                SubjectService.sendEmailVerification(identifier, getValues('email'), auth.token!, ForLegalIzvor_reg as string).subscribe((data) => {
                                    onGetSubject();
                                    setTimeout(() => {
                                        onPopUpEnable();
                                    }, 1000);
                                });
                            }}
                        >
                            {i18n.t('email.changeEmail')}
                        </Button>
                    </Grid>
                    {auth.user?.email && (
                        <Grid item xs={12} sm={3}>
                            <Button
                                disabled={!!errors.email?.message}
                                style={{ width: '100%' }}
                                onClick={handleDeleteClick}
                            >
                                {i18n.t('email.deleteEmail')}
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </form>
        </>
    );
}

type EmailDeleteDialogProps = {
    isOpen: boolean;
    onDeleteSubmit: () => void;
    onClose: () => void;
}

const EmailDeleteDialog = ({ isOpen, onDeleteSubmit, onClose }: EmailDeleteDialogProps) => {


    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            disableAutoFocus
        >
            <div className="dialog__top-container">
                <div className="dialog__top-container-title">
                    {i18n.t('email.deleteTitle')}
                </div>
                <div className="dialog__top-container-icon" onClick={onClose}>
                    <CloseIcon />
                </div>
            </div>
            <div className="dialog__buttons">
                <Button className="dialog__buttons-delete-button" onClick={onDeleteSubmit}>{i18n.t('email.delete')}</Button>
                <Button className="dialog__buttons-quit-button" onClick={onClose}>{i18n.t('email.cancel')}</Button>
            </div>
        </Dialog>
    );
}