import React from 'react';
import { RouteInterface } from "../interfaces/framework/routing.interface";
import { Switch, Route } from 'react-router-dom';
import GuestRouteComponent from '../components/routeGuards/guestRoute.component';
import AuthRouteComponent from '../components/routeGuards/authRoute.component';
import { NotFoundComponent } from '../../features/main/pages/notFound.component';
import { AppRoutes } from '../constants';
import { LoginRouteComponent } from '../components/routeGuards/loginRoute.component';



export enum RouteProtectionLevel {
    GUEST_ONLY = "guest",
    AUTH_ONLY = "authenticated",
    LOGIN = "login"
}

let _navigator: any;

function setTopLevelNavigator(navigatorRef: any) {
    _navigator = navigatorRef;
}

function navigate(route: string, state?: any) {
    _navigator.history.push(route, state);
}

function goBack() {
    _navigator.history.goBack();
}

function generateRoutes(routes: Array<RouteInterface>): React.ReactNode {
    return (
        <Switch>
            {routes.map((route: RouteInterface, index: number) => {
                switch (route.guard) {
                    case RouteProtectionLevel.GUEST_ONLY:
                        return (
                            <GuestRouteComponent exact={route.exact} key={index} path={route.path} component={route.component} />
                        );
                    case RouteProtectionLevel.AUTH_ONLY:
                        return (
                            <AuthRouteComponent exact={route.exact} key={index} path={route.path} component={route.component} />
                        );
                    case RouteProtectionLevel.LOGIN:
                        return (
                            <LoginRouteComponent exact={route.exact} key={index} path={route.path} component={route.component} />
                        );
                    default:
                        return (
                            <Route exact={route.exact} key={index} path={route.path} component={route.component} />
                        )
                }
            })}
            <Route component={NotFoundComponent} path={AppRoutes.NotFound}></Route>
            <Route component={NotFoundComponent}></Route>
        </Switch>
    )
}


export const NavigationService = {
    navigate,
    setTopLevelNavigator,
    generateRoutes,
    goBack
};
