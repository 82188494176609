import React from "react";
import { withKeycloak } from '@react-keycloak/web';
import { AppRoutes, NavigationService, store } from "../../../../common";
import { KeycloakInstance } from "keycloak-js";

type PropsType = {
    keycloakInitialized: boolean;
    keycloak: KeycloakInstance;
}


export class LoginRedirectComponent extends React.Component<PropsType> {

    componentDidMount() {
        setTimeout(() => {
            if (!store.getState().auth.isAuthentificated) {
                this.props.keycloak.login({
                    idpHint: 'saml-nias'
                });
            } else {
                NavigationService.navigate(AppRoutes.Dashboard.Index);
            }
        }, 2000)
    }

    render() {
        return (
            <div>
                Preusmjeravanje...
            </div>
        );
    }
}

export default withKeycloak(LoginRedirectComponent)

