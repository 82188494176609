import { SystemInfoInterface } from '../../interfaces/index';
import { AnyAction } from 'redux';

export interface SystemsStateInterface {
    data: Array<SystemInfoInterface>;
}

const initialState: SystemsStateInterface = {
    data: [
        { categoryId: 1, title: "Poljoprivreda", logo: "icon icon-Poljoprivreda", subsystems: [] },
        { categoryId: 2, title: "Ribarstvo", logo: "icon icon-Ribarstvo", subsystems: [] },
        { categoryId: 3, title: "\u0160umarstvo", logo: "icon icon-Sumarstvo", subsystems: [] },
        { categoryId: 4, title: "Veterina", logo: "icon icon-Veterina", subsystems: [] }
    ]
}



export function systemsReducer(state: SystemsStateInterface = initialState, action: AnyAction): SystemsStateInterface {
    switch(action.type) {
    }
    return state;
}
