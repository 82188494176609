import React from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import LocationIcon from '@material-ui/icons/LocationOn';
import WebIcon from '@material-ui/icons/DesktopWindows';
import MailIcon from '@material-ui/icons/Mail';
import CallIcon from '@material-ui/icons/Call';
import i18n from './translate.service';

function aboutSystemModalContent(): React.ReactNode {
    return (
        <>
            <p>{i18n.t('frontPage.modal.paragraph1')}</p>
            <p>{i18n.t('frontPage.modal.paragraph2')}</p>
            <p>{i18n.t('frontPage.modal.paragraph3')}</p>
            <p>{i18n.t('frontPage.modal.paragraph4')}</p>
            <p>{i18n.t('frontPage.modal.paragraph5')}</p>
        </>
    )
}

function contactModalContent(): React.ReactNode {
    return (
        <DialogContent className="landing__modal-content landing__modal-contact">
            <div className="paragraph">
                <a href="http://maps.google.com/?q=Ulica grada Vukovara 78, 10000 Zagreb" rel="noopener noreferrer" target="_blank"><LocationIcon />Ulica grada Vukovara 78, 10000 Zagreb</a>
            </div>
            <div className="paragraph">
                <a href="https://poljoprivreda.gov.hr/" rel="noopener noreferrer" target="_blank"><WebIcon />poljoprivreda.gov.hr</a>
            </div>
            <div className="paragraph">
                <a href="mailto:glasnogovornik@mps.hr"><MailIcon />glasnogovornik@mps.hr</a>
            </div>
            <div className="paragraph">
                <a href="tel:glasnogovornik@mps.hr"><CallIcon />01 6106 111</a>
            </div>
        </DialogContent>
    )
}

export const ModalsService = {
    aboutSystemModalContent,
    contactModalContent
};