import { Dialog } from "@material-ui/core";
import MuiDialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { withKeycloak } from '@react-keycloak/web';
import React from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import { RouteComponentProps } from "react-router-dom";
import { Element } from 'react-scroll';
import { ApiRoutes, AppRoutes, AuthStateInterface, Loading, NavigationService, ScrollToNav, StoreStateInterface, SubsystemInfoInterface, SubystemsStateInterface, httpHeader } from "../../../../common";
import i18n from "../../../../common/services/translate.service";
import './subsystem.styles.scss';
//@ts-ignore
import { KeycloakInstance } from "keycloak-js";
//@ts-ignore
import { PDFReader } from 'reactjs-pdf-reader';

type PathParamsType = {
    id: string;
}

type PropsType = RouteComponentProps<PathParamsType>  & {
    subsystems: SubystemsStateInterface;
    auth: AuthStateInterface;
    keycloakInitialized: boolean;
    keycloak: KeycloakInstance;
}

interface State {
    currentSubsystem?: any;
    openPDFModal: boolean;
    pdfBlob?: any;
    isDarkMode: boolean;
}

class SubsystemComponent extends React.Component<PropsType, State> {
    unlisten: any;
    private interval: any;

    constructor(props: PropsType) {
        super(props);
        this.state = {
            currentSubsystem: undefined,
            openPDFModal: false,
            isDarkMode: false
        }

        this.setCurrentSubsystem = this.setCurrentSubsystem.bind(this);
    }

    private getCookie(cname: string): string {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    private setCurrentSubsystem(): void {
        if (this.props.location.state === undefined) NavigationService.navigate(AppRoutes.Dashboard.Index)
        this.setState({ currentSubsystem: this.props.location.state })
    }

    componentDidMount() {
        this.setCurrentSubsystem();
this.interval = setInterval(() => {
            //@ts-ignore
            if (this.getCookie("cookieImpaired") === "da") {
                this.setState({ isDarkMode: true });
            }
            else {
                this.setState({ isDarkMode: false });
            }
        }
            , 1000)
    }


    componentWillUnmount() {
    }

    private renderLoginUrl(subsystem: SubsystemInfoInterface | undefined): string {
        if (subsystem === undefined) return "";
        let allowedSystems = this.props.subsystems.data?.filter((data: SubsystemInfoInterface) => data.aktivan).filter((data: SubsystemInfoInterface) => {
            let find = this.props.auth.user?.pravaPristupa?.find((pravo: any) => pravo.podsustavId === data.id && pravo.aktivan === true);
            return find !== undefined;
        })
        if (allowedSystems === undefined) return subsystem.url;
        let find = allowedSystems.find((system: SubsystemInfoInterface) => system.naziv.includes("Agronet"));
        if (subsystem.naziv.includes("Agronet") && find !== undefined) {
            return `${subsystem.url}rpslogin.aspx?sliu=${process.env.REACT_APP_ROOT}/aplikacije`
        }
        if (find !== undefined)
            return subsystem.url + `?token=${this.props.auth.token}`
        return subsystem.url;
    }

    private checkIfAllowed(subsystem: SubsystemInfoInterface | undefined): boolean  {
        if (subsystem === undefined) return false;
        let find = this.props.auth.user?.pravaPristupa?.find((pravo: any) => pravo.podsustavId === subsystem.id && pravo.aktivan === true);
        return find !== undefined;
    }

    private renderBanner(): React.ReactNode {

        return (
            <section className="subsystem__banner">
                <div className="subsystem__banner-background">
                    <div className="subsystem__banner-background-left">
                    </div>
                    <div className="subsystem__banner-background-right">
                    </div>
                </div>
                <div className="subsystem__banner-container">
                    <div className="subsystem__banner-left">
                        <div className="title title--bold title--green subsystem__banner-title">{this.state.currentSubsystem?.naziv}</div>
                        <div className="subsystem__banner-description" dangerouslySetInnerHTML={{ __html: this.state.currentSubsystem?.kratakOpis}}></div>
                        <div className="subsystem__banner-actions">
                            {(this.checkIfAllowed(this.state.currentSubsystem)) &&
                                <a href={this.renderLoginUrl(this.state.currentSubsystem)} className="button button--contained">{i18n.t("subsystem.apply")}</a>
                            }
                            {/* eslint-disable-next-line*/
                            (this.state.currentSubsystem?.registracijaUrl !== null && this.state.currentSubsystem?.registracijaUrl !== undefined && !this.checkIfAllowed(this.state.currentSubsystem)) && <a href={this.state.currentSubsystem?.registracijaUrl.length > 0 ? this.state.currentSubsystem?.registracijaUrl : ""} className="button button--outlined">{i18n.t("subsystem.register")}</a>
                            }
                        </div>
                    </div>
                    <div className="subsystem__banner-right">
                        <img src={`data:image/jpeg;base64,${this.state.isDarkMode ? this.state.currentSubsystem?.slikaAlt : this.state.currentSubsystem?.slika}`} alt={this.state.currentSubsystem?.naziv}></img>
                    </div>
                </div>
            </section>
        )
    }

    private renderPDFModal(): React.ReactNode {
        return (
            <Dialog maxWidth="xl" onClose={() => this.setState({ openPDFModal: false })} aria-labelledby="customized-dialog-title" open={this.state.openPDFModal}>
                <DialogTitle className="landing__modal-title"  id="customized-dialog-title">
                    <div className="title title--bold subsystem__section-title">{i18n.t("subsystem.howToRegister")}</div>
                    <CloseIcon onClick={() => this.setState({ openPDFModal: false })} style={{ alignSelf: "flex-start" }} />
                </DialogTitle>
                <MuiDialogContent>
                    <PDFReader url={this.state.pdfBlob} showAllPage={true} />
                </MuiDialogContent>
            </Dialog>
            )
    }

    private renderAgronetInfo(): React.ReactNode {
        let cleanText = "Za Registraciju u Agronet zahtjev je moguće predati osobno ili elektronski. Upute za registraciju nalaze se ovdje.".replace("ovdje.", "");
        return (
            <>
                <span dangerouslySetInnerHTML={{ __html: cleanText }}></span>
                {/* eslint-disable-next-line*/}
                <a style={{ cursor: "pointer", textDecoration: "underline" }}
                    onClick={() => {
                        const requestOptions = {
                            method: "GET",
                            headers: httpHeader()
                        };

                        let url = ApiRoutes.Document(this.state.currentSubsystem!.id!);
                        fetch(url, requestOptions)
                            .then((response: any) => {
                                response.blob().then((blob: any) => {
                                    var fileURL = URL.createObjectURL(blob);
                                    this.setState({ pdfBlob: fileURL, openPDFModal: true })
                                })
                            })
                    }}>ovdje.</a>
                </>
            )
    }

    render() {
        return (
            <article className="subsystem">
                <Loading isLoading={this.state.currentSubsystem === undefined}>
                    {this.renderBanner()}
                    <section className="subsystem__info">
                        <div className="subsystem__info-left">
                            <Element name="aboutSubsystem" className="subsystem__info-element">
                                <div className="title title--bold subsystem__section-title">{i18n.t("subsystem.aboutSubsystem")}</div>
                                <div dangerouslySetInnerHTML={{ __html: this.state.currentSubsystem ? this.state.currentSubsystem.opis : "" }}></div>
                            </Element>
                            <Element name="howToRegister" className="subsystem__info-element">
                                <div className="title title--bold subsystem__section-title">{i18n.t("subsystem.howToRegister")}</div>
                                {!this.state.currentSubsystem?.naziv.includes("Agronet") &&
                                    <div dangerouslySetInnerHTML={{ __html: this.state.currentSubsystem ? this.state.currentSubsystem.registracijaInfo : "" }}></div>
                                }
                                {this.state.currentSubsystem?.naziv.includes("Agronet") &&
                                    this.renderAgronetInfo()
                                }
                            </Element>
                            <Element name="someTitle" className="subsystem__info-element">
                                <div className="title title--bold subsystem__section-title">{i18n.t("subsystem.contact")}</div>
                                <div dangerouslySetInnerHTML={{ __html: this.state.currentSubsystem ? this.state.currentSubsystem.kontaktInfo : "" }}></div>
                            </Element>
                        </div>
                        <ScrollToNav className="subsystem__info-right"
                            offset
                            navLinks={[
                                { to: "aboutSubsystem", title: i18n.t("subsystem.aboutSubsystem") },
                                { to: "howToRegister", title: i18n.t("subsystem.howToRegister") },
                                { to: "someTitle", title: i18n.t("subsystem.someTitle") }
                            ]}>
                        </ScrollToNav>
                        <ScrollToNav className="subsystem__info-right mobile"
                            navLinks={[
                                { to: "aboutSubsystem", title: i18n.t("subsystem.aboutSubsystem") },
                                { to: "howToRegister", title: i18n.t("subsystem.howToRegister") },
                                { to: "someTitle", title: i18n.t("subsystem.someTitle") }
                            ]}>
                        </ScrollToNav>
                    </section>
                </Loading>
                {this.renderPDFModal()}
            </article>
        );
    }
}

const mapStateToProps = (state: StoreStateInterface) => {
    return {
        subsystems: state.subsystems,
        auth: state.auth
    }
}

const actions = {
};

const redux = withRouter(connect(
    mapStateToProps,
    actions
)(SubsystemComponent));

export default withKeycloak(redux)