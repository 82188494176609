import { I18n } from 'i18n-js';

import en from '../i18n/en.json';
import hr from '../i18n/hr.json';

const i18nInit = new I18n({
    ...en,
    ...hr,
});

const lang = localStorage.getItem('lang') !== null ? localStorage.getItem('lang')! : "hr";


i18nInit.defaultLocale = lang;
i18nInit.locale = lang;
i18nInit.enableFallback = true;
i18nInit.translations = { en, hr };


export const i18n = i18nInit;

export const setLang = (l: string | null) => {
    if (l === null) return;
    i18n.locale = l;
    localStorage.setItem('lang', l);
    document.dispatchEvent(new Event("refreshLang"))
};

export default i18n;