import { RouteInterface } from "../../../common/interfaces/framework/routing.interface";
import { AppRoutes, RouteProtectionLevel } from "../../../common";
import ApplicationsComponent from "../pages/applications/applications.component";
import ProfileComponent from "../pages/profile/profile.component";
import SubsystemComponent from '../pages/subsystem/subsystem.component';
import EObrasciComponent from "../pages/eObrasci/eObrasci.component";
import { LoginRedirectComponent } from "../../landing/pages/loginRedirect/loginRedirect.component";

export const DashboardNavigationRouting = (): Array<RouteInterface> => {
    return [
        { path: AppRoutes.Dashboard.EObrasci, component: EObrasciComponent, exact: true },
        { path: AppRoutes.Dashboard.Profile, component: ProfileComponent, exact: true },
        { path: AppRoutes.Dashboard.Subsystem, component: SubsystemComponent, exact: true },
        { path: AppRoutes.Dashboard.Index, component: ApplicationsComponent, exact: false, guard: RouteProtectionLevel.LOGIN }
    ]
}

