import React, { Component } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import './loading.styles.scss';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button } from '../button/button.component';
import i18n from '../../services/translate.service';

interface Props {
    isLoading: boolean;
    error?: string;
}

interface State {
}

export class Loading extends Component<Props, State> {

    render() {
        return (
            <>
                {this.props.isLoading &&
                    <div className="loading">
                        <CircularProgress />
                    </div>
                }
                {this.props.error &&
                    <Dialog
                        open={true}
                        onClose={() => { localStorage.clear(); window.location.reload(); }}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title"> {i18n.t('common.error')}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {this.props.error}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => { localStorage.clear(); window.location.reload(); }} type="contained">
                                {i18n.t('common.reset')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                }
                {!this.props.isLoading && this.props.error === undefined &&
                    <>{this.props.children}</>
                }
            </>
        )
    }
}