import { Observable, from } from "rxjs";
import { store, persistor } from "../redux/store";
import { ErrorConstant } from '../redux/index';
/**
 * GET request factory
 *
 * @param {string}    path          Resource path
 * @param {object}    params        Request query params
 */
function GET(path: string, params?: HttpParamsTypes, token?: string): Observable<any> {
    const requestOptions = {
        method: "GET",
        headers: httpHeader(token)
    };

   // let url = [process.env.REACT_APP_API_URL, path].join("/");
    let url = path;
    url = getUrlWithParams(url, params);

    return from(
        fetch(url, requestOptions)
            .then(parseResponse)
            .catch(handleNetworkError)
    );
}

/**
 * POST request factory
 *
 * @param {string}    path          Resource path
 * @param {object}    body          Request payload
 * @param {object}    params        Request query params
 */
function POST(
    path: string,
    body: any,
    params?: HttpParamsTypes,
    token?: string
): Observable<any> {
    const requestOptions = {
        method: "POST",
        headers: {
            ...httpHeader(token),
            "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
    };

    //let url = [process.env.REACT_APP_API_URL, path].join("/");
    let url = path;
    url = getUrlWithParams(url, params);

    return from(
        fetch(url, requestOptions)
            .then(parseResponse)
            .catch(handleNetworkError)
    );
}

/**
 * POST request factory
 *
 * @param {string}    path          Resource path
 * @param {object}    body          Request payload
 * @param {object}    params        Request query params
 */
function POST_URLENCODED(
    path: string,
    body: any,
    params?: HttpParamsTypes,
    token?: string
): Observable<any> {
    const formBody = Object.keys(body).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(body[key])).join('&');

    const requestOptions = {
        method: "POST",
        headers: {
            ...httpHeader(token),
            "Content-Type": "application/x-www-form-urlencoded"
        },
        body: formBody
    };

    //let url = [process.env.REACT_APP_API_URL, path].join("/");
    let url = path;
    url = getUrlWithParams(url, params);

    return from(
        fetch(url, requestOptions)
            .then(parseResponse)
            .catch(handleNetworkError)
    );
}

/**
 * PUT request factory
 *
 * @param {string}    path          Resource path
 * @param {object}    body          Request payload
 * @param {object}    params        Request query params
 */
function PUT(
    path: string,
    body: any,
    params?: HttpParamsTypes
): Observable<any> {
    const requestOptions = {
        method: "PUT",
        headers: {
            ...httpHeader(),
            "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
    };

    //let url = [process.env.REACT_APP_API_URL, path].join("/");
    let url = path;
    url = getUrlWithParams(url, params);

    return from(
        fetch(url, requestOptions)
            .then(parseResponse)
            .catch(handleNetworkError)
    );
}

function PATCH(
    path: string,
    body: any,
    params?: HttpParamsTypes,
    token?: string
): Observable<any> {
    const requestOptions = {
        method: "PATCH",
        headers: {
            ...httpHeader(token),
            "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
    };

    //let url = [process.env.REACT_APP_API_URL, path].join("/");
    let url = path;
    url = getUrlWithParams(url, params);

    return from(
        fetch(url, requestOptions)
            .then(parseResponse)
            .catch(handleNetworkError)
    );
}

/**
 * DELETE request factory
 *
 * @param {string}    path          Resource path
 * @param {object}    params        Request query params
 */
function DELETE(path: string, params?: HttpParamsTypes): Observable<any> {
    const requestOptions = {
        method: "DELETE",
        headers: {
            ...httpHeader(),
            "Content-Type": "application/json"
        }
    };

    //let url = [process.env.REACT_APP_API_URL, path].join("/");
    let url = path;
    url = getUrlWithParams(url, params);

    return from(
        fetch(url, requestOptions)
            .then(parseResponse)
            .catch(handleNetworkError)
    );
}

// ====== PRIVATE UTILS ======

function getUrlWithParams(url: string, params?: HttpParamsTypes) {
    if (params === null) return url;

    let p: string = "";
    if (params) {
        p = "?";
        let keys = Object.keys(params);
        for (let i in keys) {
            if (p !== "?") p += "&";
            p +=
                encodeURIComponent(keys[i]) + "=" + encodeURIComponent(params[keys[i]]);
        }
    }

    return [url, p].join("");
}

/**
 * Requests header generator
 */
export function httpHeader(token?: string) {
    let header: any = {};
    var APP_TOKEN = token;

    let currentState: any = store.getState();
    if (
        currentState !== undefined &&
        currentState.auth !== undefined &&
        currentState.auth.token !== undefined
    ) {
        APP_TOKEN = currentState.auth.token;
    }

    if (APP_TOKEN) header["Authorization"] = "Bearer " + APP_TOKEN;

    return header;
}

function parseResponse(response: any) {

    /* if (response.headers.has("newToken")) {
       let newToken = response.headers.get("Authorization");
       store.dispatch({ type: AuthConstants.RENEW_USER_TOKEN, payload: newToken });
     } */

    return response.text().then((text: any) => {
        let data;

        try {
            data = text && JSON.parse(text);
        } catch (e) {
            data = null;
        }

        if (!response.ok) {
            if (response.status === 401) {
                store.dispatch({
                    type: ErrorConstant.ERROR, payload:
                    {
                        message: "Trenutno niste prijavljeni u sustav",
                        action: () => { }
                    }
                }
                )
                persistor.purge();
                return;
            }
        }

        if (!response.ok) {
            if (!response.ok) {
                if (response.status < 500 && response.status > 399) {
                    throw new ClientError(response.status);
                } else {
                    throw new ServerError(response.status);
                }
            }
        }

        return data;
    });
}

// ====== INTERFACES ======

export type HttpParamsTypes = { [index: string]: string };

export function handleNetworkError(error: any) {
    if (error.name !== ErrorType.clientError && error.name !== ErrorType.serverError) {
        persistor.purge();
        throw new NetworkError("");
    }
    throw error;
}


export const ErrorType = {
    clientError: "ClientError",
    serverError: "ServerError",
    networkError: "NetworkError"
}

class ClientError extends Error {
    constructor(message: string) {
        super(message);
        this.name = ErrorType.clientError;
    }
}

class ServerError extends Error {
    constructor(message: string) {
        super(message);
        this.name = ErrorType.serverError;
    }
}

class NetworkError extends Error {
    constructor(message: string) {
        super(message);
        this.name = ErrorType.networkError;
    }
}



export default {
    GET, POST, PUT, DELETE, PATCH, POST_URLENCODED
};
