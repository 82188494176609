import React, { Component } from "react";
import './footer.styles.scss';
import { LinksConstant } from "../../constants";
import { Dialog } from "@material-ui/core";
import DialogTitle from '@material-ui/core/DialogTitle';
import { ModalsService } from "../..";
import CloseIcon from '@material-ui/icons/Close';
import i18n from "../../services/translate.service";

type Props = {}

interface State {
    aboutSystem: boolean;
    contact: boolean;
}

export class Footer extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            aboutSystem: false,
            contact: false
        }
    }

    private aboutSystemModal(): React.ReactNode {
        return (
            <Dialog maxWidth="lg" onClose={() => this.setState({ aboutSystem: false })} aria-labelledby="customized-dialog-title" open={this.state.aboutSystem}>
                <div className="dialog__top-container-icon-sticky" onClick={() => this.setState({ aboutSystem: false })}>
                    <CloseIcon />
                </div>
                <DialogTitle className="landing__modal-title" id="customized-dialog-title">
                    {i18n.t('footer.about')}
                </DialogTitle>
                {ModalsService.aboutSystemModalContent()}
            </Dialog>
        )
    }

    private contactModal(): React.ReactNode {
        return (
            <Dialog onClose={() => this.setState({ contact: false })} aria-labelledby="customized-dialog-title" open={this.state.contact}>
                <div className="dialog__top-container-icon-sticky" onClick={() => this.setState({ contact: false })}>
                    <CloseIcon />
                </div>
                <DialogTitle className="landing__modal-title" id="customized-dialog-title">
                    {i18n.t('footer.contact')}
                </DialogTitle>
                {ModalsService.contactModalContent()}
            </Dialog>
        )
    }


    render() {
        return (
            <footer className="footer__wrapper">
                <div className="footer">
                    <div className="footer__links">
                        {/* eslint-disable-next-line*/}
                        <a href="#" onClick={() => this.setState({ aboutSystem: true })}>{i18n.t('footer.about')}</a>
                        {/* eslint-disable-next-line*/}
                        <a href="#" onClick={() => this.setState({ contact: true })}>{i18n.t('footer.contact')}</a>
                        <a href={LinksConstant.ministarstvo} target="_blank" rel="noopener noreferrer">
                            {i18n.t('footer.ministryOfAgriculture')}
                        </a>
                        <a href={LinksConstant.egradani} target="_blank" rel="noopener noreferrer">
                            {i18n.t('footer.eCitizens')}
                        </a>
                    </div>
                </div>
                {this.aboutSystemModal()}
                {this.contactModal()}
            </footer>
        )
    }
}