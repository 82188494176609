import { AnyAction } from 'redux';
import { SubsystemInfoInterface } from '../../interfaces';
import { SubsystemsConstant } from './subSystems.constant';

export interface SubystemsStateInterface {
    data?: Array<SubsystemInfoInterface>;
    isLoading: boolean;
    error?: string;
}

const initialState: SubystemsStateInterface = {
    isLoading: false
}


export const getSubsystemsInfoStart = (state: SubystemsStateInterface): SubystemsStateInterface => {
    return {
        isLoading: true
    }
}

export const getSubsystemsInfoSuccess = (state: SubystemsStateInterface, payload: Array<SubsystemInfoInterface>): SubystemsStateInterface => {
    return {
        isLoading: false,
        data: payload
    }
}

export const getSubsystemsInfoError = (state: SubystemsStateInterface, payload: string): SubystemsStateInterface => {
    return {
        isLoading: false,
        data: undefined,
        error: payload
    }
}

export function subsystemsReducer(state: SubystemsStateInterface = initialState, action: AnyAction): SubystemsStateInterface {
    switch (action.type) {
        case SubsystemsConstant.GET_SUBSYSTEMS_START: return getSubsystemsInfoStart(state);
        case SubsystemsConstant.GET_SUBSYSTEMS_SUCCESS: return getSubsystemsInfoSuccess(state, action.payload);
        case SubsystemsConstant.GET_SUBSYSTEMS_ERROR: return getSubsystemsInfoError(state, action.payload);
    }
    return state;
}
