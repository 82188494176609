import { createStore, applyMiddleware, compose } from "redux";
//import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from "redux-thunk";
import { AppReducers } from "./index.reducer";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
  key: 'epoljoprivreda',
  storage,
    whitelist: ['auth', 'base']
}

const persistedReducer = persistReducer(persistConfig, AppReducers)

const initialState = {};

const middleware = [thunk];

export const store = createStore(
  persistedReducer,
  initialState,
  //composeWithDevTools(applyMiddleware(...middleware))
  compose(applyMiddleware(...middleware))
);

export const persistor = persistStore(store)

