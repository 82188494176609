import React from "react";

interface Props {
}

export class NotFoundComponent extends React.Component<Props> {
    render() {
        return (
            <div>
                404: Page not found
            </div>
        );
    }
}


