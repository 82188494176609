import qs from "qs";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import { AuthStateInterface, QueryParamsConstant, StoreStateInterface } from "../..";


interface Props {
    auth: AuthStateInterface;
    component: any;
    path: string;
    exact: boolean;
}

interface State {
}

class LoginRouteComponentClass extends Component<Props, State> {
    private callbackUrl: string = "";
    private redirectUrl: string = "";
    private redirectStarted: boolean = false;

    private getQueryParam(paramName: string): string {
        let qp = qs.parse(window.location.search.replace('?', ''));
        let search: string | undefined = "";
        if (qp[paramName]) {
            search = qp[paramName]?.toString();
        }
        return search ? decodeURI(search) : "";
    }

    private redirectBack(token: string, itoken?: string): void {
        this.redirectStarted = true;
        let url = this.callbackUrl;
        url += "?" + QueryParamsConstant.Token + "=" + token;
        localStorage.setItem("agronetLogin", "1")

        window.location.href = url
    }

    render() {
        this.callbackUrl = this.getQueryParam(QueryParamsConstant.CallbackUrl);
        this.redirectUrl = this.getQueryParam(QueryParamsConstant.RedirectUrl);
        if (this.callbackUrl.length > 0) {
            if (!this.redirectStarted) {
                if (this.props.auth.token)
                    this.redirectBack(this.props.auth.token!);
            }
        }
        return <Route exact={this.props.exact} path={this.props.path} component={this.props.component} />
    }
}

const mapStateToProps = (state: StoreStateInterface) => {
    return {
        auth: state.auth
    };
};

const actions = {
};

export const LoginRouteComponent = connect(mapStateToProps, actions)(LoginRouteComponentClass);
