import { withKeycloak } from '@react-keycloak/web';
import { KeycloakInstance } from "keycloak-js";
import React from "react";
import { connect } from "react-redux";
import { AppRoutes, NavigationService, QueryParamsConstant, StoreStateInterface } from "../../../../common";
import { AuthStateInterface, actionLogout, persistor } from '../../../../common/redux';
import keycloak from '../../../../keycloak';

type PropsType = {
    actionLogout: () => void;
    auth: AuthStateInterface;
    keycloakInitialized: boolean;
    keycloak: KeycloakInstance;
}


export class LogoutRedirectComponent extends React.Component<PropsType> {
    componentDidMount() {
        setTimeout(() => {
            localStorage.removeItem("initialUser")
            if (this.props.auth.isAuthentificated) {
                persistor.purge();
                this.props.keycloak.logout();
            } else {
                window.location.href = "/"
            }
        }, 1000)
    }

    render() {
        return (
            <div>
                Preusmjeravanje...
            </div>
        );
    }
}

const mapStateToProps = (state: StoreStateInterface) => {
    return {
        auth: state.auth
    }
}

const actions = {
    actionLogout
};

const redux = connect(
    mapStateToProps,
    actions
)(LogoutRedirectComponent);

export default withKeycloak(redux)
