import Keycloak from 'keycloak-js';
 
// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'

export const KeyCloakRoute = process.env.REACT_APP_KC_URL;

//@ts-ignore
const keycloak: Keycloak.KeycloakInstance = new Keycloak(
    {
        "url": KeyCloakRoute,
        "realm": process.env.REACT_APP_KC_REALM!,
        "clientId": process.env.REACT_APP_KC_REALM!
    }
)



export default keycloak