import { AuthConstant } from "./auth.constant";
import { AnyAction } from "redux";
import { UserInterface } from "../../interfaces";
import { PURGE } from "redux-persist";

export interface AuthStateInterface {
    isAuthentificated: boolean;
    user?: UserInterface;
    token?: string;
    MessageId?: string;
    NavToken?: string;
    errorMessage?: string;
    refreshToken?: string;
}

const initialState: AuthStateInterface = {
    isAuthentificated: false
}

export const login = (state: AuthStateInterface, payload: any): AuthStateInterface => {
    return {
        ...state,
        isAuthentificated: true,
        user: payload.user,
        token: payload.token,
        refreshToken: payload.refreshToken,
        MessageId: payload.MessageId,
        NavToken: payload.NavToken
    }
}

export const logout = (state: AuthStateInterface): AuthStateInterface => {
    return {
        ...state,
        isAuthentificated: false,
        user: undefined,
        token: undefined,
        refreshToken: undefined,
        MessageId: undefined,
        NavToken: undefined
    }
}

// TODO: Figure out getSubject for eidas users
export const getSubject = (state: AuthStateInterface, payload: any): AuthStateInterface => {
    return {
        ...state,
        user: payload,
    }
}

export const enablePopUpState = (state: AuthStateInterface): AuthStateInterface => {
    return {
        ...state,
        user: {
            ...state.user!,
            isEmailPopUpDisabled: false
        },
    }
}

export const changeOib = (state: AuthStateInterface, payload: any): AuthStateInterface => {
    return {
        ...state,
        user: {
            ...state.user!,
            oib: payload
        },
    };
}

export const refreshToken = (state: AuthStateInterface, payload: { accessToken: string, refreshToken: string }): AuthStateInterface => {
    return {
        ...state,
        token: payload.accessToken,
        refreshToken: payload.refreshToken
    };
}

export function authReducer(state: AuthStateInterface = initialState, action: AnyAction): AuthStateInterface {
    switch(action.type) {
        case AuthConstant.LOGIN: return login(state, action.payload);
        case AuthConstant.LOGOUT: return logout(state);
        case PURGE: return initialState;
        case AuthConstant.GET_SUBJECT: return getSubject(state, action.payload);
        case AuthConstant.ENABLE_POPUP: return enablePopUpState(state);
        case AuthConstant.CHANGE_OIB: return changeOib(state, action.payload);
        case AuthConstant.REFRESH_TOKEN: return refreshToken(state, action.payload);
    }
    return state;
}
